import React from "react";
import {Route} from "react-router-dom";
import Hoc from "./hoc/hoc";
import LoginPage from "./pages/LoginPage";
import Homepage from "./pages/Homepage";
import CreateWarehousePage from "./pages/CreateWarehousePage";
import CreateContainerPage from "./pages/CreateContainerPage";
import CreateCustomerPage from "./pages/CreateCustomerPage";
import CreateShipmentPage from "./pages/CreateShipmentPage";
import CreateUserPage from "./pages/CreateUserPage";
import CreateCategoryPage from "./pages/CreateCategoryPage";
import CreateConsignmentPage from "./pages/CreateConsignmentPage";
import WarehouseListPage from "./pages/WarehouseListPage";
import CategoryListPage from "./pages/CategoryListPage";
import UsersListPage from "./pages/UsersListPage";
import CreateSupplierPage from "./pages/CreateSupplierPage";
import CreateGoodPage from "./pages/CreateGoodPage";
import AllGoodsPage from "./pages/AllGoodsPage"
import AllConsignmentsPage from "./pages/AllConsignmentsPage";
import AllCustomersPage from "./pages/AllCustomersPage";
import AllShipmentsPage from "./pages/AllShipmentsPage";
import AllContainersPage from "./pages/AllContainersPage";
import AllSuppliersPage from "./pages/AllSuppliersPage";
import AllWhatsappMessagesPage from "./pages/AllWhatsappMessagesPage";
import CreateWhatsappMessagePage from "./pages/CreateWhatsappMessagePage";
import AllSmsPage from "./pages/AllSmsPage";
import CreateSmsMessagePage from "./pages/CreateSmsMessagePage";

const BaseRouter = () => (
    <Hoc>
        <Route exact path="/login" component={LoginPage}/>
        <Route exact path="/" component={Homepage}/>
        <Route exact path="/suppliers/new" component={CreateSupplierPage}/>
        <Route exact path="/suppliers" component={AllSuppliersPage}/>
        <Route exact path="/warehouses/new" component={CreateWarehousePage}/>
        <Route exact path="/goods/new" component={CreateGoodPage}/>
        <Route exact path="/goods" component={AllGoodsPage}/>
        <Route exact path="/warehouses" component={WarehouseListPage}/>
        <Route exact path="/customers" component={AllCustomersPage}/>
        <Route exact path="/categories" component={CategoryListPage}/>
        <Route exact path="/containers" component={AllContainersPage}/>
        <Route exact path="/consignments" component={AllConsignmentsPage}/>
        <Route exact path="/shipments" component={AllShipmentsPage}/>
        <Route exact path="/whatsapp" component={AllWhatsappMessagesPage}/>
        <Route exact path="/whatsapp/new" component={CreateWhatsappMessagePage}/>
        <Route exact path="/sms" component={AllSmsPage}/>
        <Route exact path="/sms/new" component={CreateSmsMessagePage}/>
        <Route exact path="/customers/new" component={CreateCustomerPage}/>
        <Route path="/containers/new" component={CreateContainerPage}/>
        <Route path="/shipments/new" component={CreateShipmentPage}/>
        <Route exact path="/users" component={UsersListPage}/>
        <Route exact path="/users/new-user" component={CreateUserPage}/>
        <Route path="/categories/new" component={CreateCategoryPage}/>
        <Route exact path="/consignments/new" component={CreateConsignmentPage}/>
    </Hoc>
);
export default BaseRouter;