import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Redirect} from "react-router";
import axios from "axios";
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import {alertService} from "../_services/alert.service";
import {LOCAL_URL} from "../utils";


class CreateSupplierPage extends Component {
    componentDidMount() {
        let user_id = localStorage.getItem('user_id');
        let access_token = localStorage.getItem("token")
        this.setState({id: user_id})
        this.setState({token: access_token})
    }

    state = {
        name: "",
        cellphone: "",
        address: "",
        errorMessage: "",
        autoClose: false,
        keepAfterRouteChange: false,
        redirectToNewPage: false
    };
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };
    handleSubmit = async (event) => {
        event.preventDefault();
        const name = this.state.name;
        const cellphone = this.state.cellphone;
        const address = this.state.address;
        const user = localStorage.getItem('user_id');
        axios.post(`${LOCAL_URL}cargo/api/suppliers/`, {
            name: name,
            cellphone: cellphone,
            address: address,
            user: user
        }).then((res) => {
            if (res.data.status === 1) {
                this.setState({redirectToNewPage: true});
                alertService.success('Success! Supplier successfully added', { autoClose: true, keepAfterRouteChange: false })
            }
        }).catch((error) => {
            this.setState({errorMessage: error.message})
            alertService.error('Error :( '+ error.message, { autoClose:true, keepAfterRouteChange: false })
        });

    };

    render() {
        const {name, cellphone, address} = this.state;
        if (this.state.redirectToNewPage) {
            return (
                <Redirect to="/suppliers"/>
            )
        }
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10 col-xl-8">

                            <div className="header mt-md-5">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">

                                            <h1 className="header-title">
                                                <i className="fe fe-users"/>&nbsp;&nbsp;&nbsp;
                                                Add a Supplier
                                            </h1>

                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/suppliers'}>
                                                    <Button variant="contained"
                                                            startIcon={<i className="fe fe-clipboard"/>}>
                                                        Suppliers</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <form onSubmit={this.handleSubmit} className="organizer-form">
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_name">Supplier Name</label>
                                            <input
                                                type='text'
                                                name='name'
                                                placeholder='ex: Guangzhou Fabric Limited'
                                                className='form-control'
                                                required='required'
                                                value={name}
                                                id='id_name'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_cellphone">Phone Number</label>
                                            <input
                                                type='text'
                                                name='cellphone'
                                                placeholder='ex: 861314567891'
                                                className='form-control'
                                                required='required'
                                                value={cellphone}
                                                id='id_cellphone'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-12 col-xl-12'>
                                        <div className="form-group">
                                            <label htmlFor="id_address">Address</label>
                                            <input
                                                type='text'
                                                name='address'
                                                placeholder='ex:  广州 广东'
                                                className='form-control'
                                                required='required'
                                                value={address}
                                                id='id_address'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    className='primaryAction btn btn-lg btn-block btn-primary mb-3'
                                    type='submit'
                                >
                                    Create Supplier
                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CreateSupplierPage;