import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Redirect} from "react-router";
import axios from "axios";
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import {alertService} from "../_services/alert.service";
import {LOCAL_URL} from "../utils";


class CreateContainerPage extends Component {
    componentDidMount() {
        let user_id = localStorage.getItem('user_id');
        let access_token = localStorage.getItem("token")
        this.setState({id: user_id})
        this.setState({token: access_token})
    }

    state = {
        container_no: "",
        shipping_line: "",
        destination: "",
        origin: "",
        shipping_date: "",
        arrival_date: "",
        seal_no: "",
        volume: "",
        errorMessage: "",
        autoClose: false,
        keepAfterRouteChange: false,
        redirectToNewPage: false
    };
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };
    handleSubmit = async (event) => {
        event.preventDefault();
        const container_no = this.state.container_no;
        const shipping_line = this.state.shipping_line;
        const destination = this.state.destination;
        const origin = this.state.origin;
        const shipping_date = this.state.shipping_date;
        const arrival_date = this.state.arrival_date;
        const seal_no = this.state.seal_no;
        const volume = this.state.volume;
        const user = localStorage.getItem('user_id');
        // console.log(container_no, shipping_line, destination, origin, shipping_date, arrival_date, seal_no, volume);
        axios.post(`${LOCAL_URL}cargo/api/containers/`, {
            container_no: container_no,
            shipping_line: shipping_line,
            destination: destination,
            origin: origin,
            shipping_date: shipping_date,
            arrival_date: arrival_date,
            seal_no: seal_no,
            volume: volume,
            user: user
        }).then((res) => {
            if (res.data.shipping_status === 0) {
                this.setState({redirectToNewPage: true});
                alertService.success('Success! Container successfully added', {
                    autoClose: true,
                    keepAfterRouteChange: false
                })
            }
        }).catch((error) => {
            this.setState({errorMessage: error.message})
            alertService.error('Error :( ' + error.message, {autoClose: true, keepAfterRouteChange: false})
        });
    };

    render() {
        const {
            container_no,
            shipping_line,
            shipping_date,
            origin,
            destination,
            arrival_date,
            seal_no,
            volume
        } = this.state;
        if (this.state.redirectToNewPage) {
            return (
                <Redirect to="/containers"/>
            )
        }
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10 col-xl-8">

                            <div className="header mt-md-3">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">

                                            <h1 className="header-title">
                                                <i className="fe fe-box"/>&nbsp;&nbsp;&nbsp;
                                                Add a Container
                                            </h1>

                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/containers'}>
                                                    <Button variant="contained" startIcon={<i className="fe fe-box"/>}>
                                                        Containers</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <form onSubmit={this.handleSubmit} className="organizer-form">
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_container_no">Container Number</label>
                                            <input
                                                type='text'
                                                name='container_no'
                                                placeholder='ex: TGBU2737816'
                                                className='form-control'
                                                required='required'
                                                value={container_no}
                                                id='id_container_no'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_shipping_line">Shipping Line</label>
                                            <select name="shipping_line" className="required form-control" required=""
                                                    id="id_shipping_line" value={shipping_line}
                                                    onChange={this.handleChange}>
                                                <option value="" selected="">---------</option>
                                                <option value="APL">APL</option>
                                                <option value="PIL">PIL</option>
                                                <option value="CMA">CMA</option>
                                                <option value="MAERSK">MAERSK</option>
                                                <option value="COSCO">COSCO</option>
                                                <option value="HPL">HPL</option>
                                                <option value="ZIM">ZIM</option>
                                            </select>
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="seal_no">Seal Number</label>
                                            <input
                                                type='text'
                                                name='seal_no'
                                                className='form-control'
                                                required='required'
                                                placeholder='ex: CK495639'
                                                value={seal_no}
                                                id='seal_no'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_volume">Volume</label>
                                            <select name="volume" className="required form-control" required=""
                                                    id="id_volume" value={volume} onChange={this.handleChange}>
                                                <option value="" selected="">---------</option>
                                                <option value="40HQ">40HQ</option>
                                                <option value="20HQ">20HQ</option>
                                            </select>
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_origin">Origin</label>
                                            <select name="origin" className="required form-control" required=""
                                                    id="id_origin" value={origin} onChange={this.handleChange}>
                                                <option value="" selected="">---------</option>
                                                <option value="Guangzhou">Guangzhou China</option>
                                                <option value="Dubai">Dubai</option>
                                            </select>
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_destination">Destination</label>
                                            <select name="destination" className="required form-control" required=""
                                                    id="id_destination" value={destination}
                                                    onChange={this.handleChange}>
                                                <option value="" selected="">---------</option>
                                                <option value="DSM"> Dar es Salaam Tanzania</option>
                                                <option value="ZNZ"> Zanzibar Tanzania</option>
                                                <option value="Mombasa"> Mombasa Kenya</option>
                                                <option value="Kampala">Kampala Uganda</option>
                                                <option value="Nakonde">Nakonde Zambia</option>
                                            </select>
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_shipping_date">Shipping Date</label>
                                            <input
                                                type='date'
                                                name='shipping_date'
                                                className='form-control'
                                                required='required'
                                                value={shipping_date}
                                                id='id_shipping_date'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_arrival_date">Arrival Date</label>
                                            <input
                                                type='date'
                                                name='arrival_date'
                                                className='form-control'
                                                required='required'
                                                value={arrival_date}
                                                id='id_arrival_date'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    className='primaryAction btn btn-lg btn-block btn-primary mb-3'
                                    type='submit'
                                >
                                    Create Container
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CreateContainerPage;
