import axios from 'axios'
import {
    CONSIGNMENTS_URL,
    DELETE_CONSIGNMENT,
    DELETE_GOOD_CONSIGNMENTS, LOCAL_URL,
    SEARCH_CONSIGNMENTS_URL
} from '../utils/index'
import React, {Component} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import {alertService} from "../_services/alert.service";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import AddIcon from "@material-ui/icons/Add";
import Chip from "@material-ui/core/Chip";
import Paper from '@material-ui/core/Paper';
import {TableContainer, Tooltip} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import InfoIcon from "@material-ui/icons/Info";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {DataGrid} from '@material-ui/data-grid';
import Alert from '@material-ui/lab/Alert';
import * as pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = () => ({
    table: {
        minWidth: 1300,
    },
    container: {
        maxHeight: 800,
    },
});
const customStyle = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ListConsignment extends Component {
    state = {
        consignments: [],
        consignmentList: [],
        consignmentDetails: [],
        previous: '',
        next: '',
        loading: true,
        loading_data: true,
        searchTerm: '',
        open: false,
        openView: false,
        detailView: false,
        deleteView: false,
        consignmentInfo: {
            id: "",
            c_name: "",
            shipping_charge: "",
            pkgs: "",
            label: "",
            cbm: "",
            warehouse: "",
            customer: "",
            price_category: "",
            delivery_status: ""
        }
    }

    componentDidMount() {
        this.loadPosts(CONSIGNMENTS_URL).then()
    }

    loadPosts = async (url) => {
        await axios.get(url)
            .then((res) => {
                const consignments = res.data.results
                this.setState({
                    consignments,
                    previous: res.data.previous,
                    next: res.data.next,
                    loading: false
                })
            })
    }

    handleDelete = async (event) => {
        event.preventDefault();
        const consignment_id = this.state.consignmentInfo.id;
        // const consignment_name = this.state.consignmentInfo.c_name;

        axios.get(`${LOCAL_URL}cargo/api/get_consignment_goods/?c=` + consignment_id).then((response) => {
            let r = response.data;
            if (r.length > 0) {
                r.forEach((item) => {
                    const prev_quantity = item.good.quantity
                    const prev_pkgs = item.good.pckg
                    const to_add_quantity = item.quantity
                    const to_add_pkgs = item.pkgs
                    const update_quantity = prev_quantity + to_add_quantity;
                    const update_pkgs = prev_pkgs + to_add_pkgs;
                    axios.patch(`${LOCAL_URL}cargo/api/goods/${item.good.id}/`, {
                        quantity: update_quantity,
                        pckg: update_pkgs,
                        status: 2
                    }).then(() => {
                        alertService.info('Success! Goods quantity and packages have been updated.', {
                            autoClose: true,
                            keepAfterRouteChange: false
                        })
                    });
                    let DELETE_URL = `${DELETE_GOOD_CONSIGNMENTS}${item.id}/`;
                    axios.delete(DELETE_URL)
                        .then(() => {
                            alertService.warn('Success! Good consignment link successfully deleted ', {
                                autoClose: true,
                                keepAfterRouteChange: false
                            })
                            window.scrollTo(0, 0);
                        });
                });
            }
        }).catch((error) => {
            console.error(error);
        });
        let DELETE_URL = `${DELETE_CONSIGNMENT}${consignment_id}/`;
        axios.delete(DELETE_URL)
            .then(() => {
                alertService.warn('Success! consignment successfully deleted ',
                    {
                        autoClose: true,
                        keepAfterRouteChange: false
                    })
                window.scrollTo(0, 0);
            });
        this.setState({deleteView: false})
    }

    prevPage = () => {
        this.setState({loading: true})

        if (this.state.previous !== null) {
            let http_link = this.state.previous
            let toPage = http_link.replace("http", "https")
            this.loadPosts(http_link).then();
        } else {
            this.loadPosts(this.state.previous).then();
        }
    }

    nextPage = () => {
        this.setState({loading: true})
        if (this.state.next !== null) {
            let http_link = this.state.next
            let toPage = http_link.replace("http", "https")
            this.loadPosts(http_link).then();
        } else {
            this.loadPosts(this.state.next).then();
        }
    }

    searchPage = () => {
        let term = this.state.searchTerm
        if (term !== "") {
            this.setState({loading: true})
            let toPage = SEARCH_CONSIGNMENTS_URL + term;
            this.loadPosts(toPage).then();
        }
    }
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleChangeEdit = (event) => {
        let newArr = this.state.consignmentInfo; // copying the old data array
        newArr[event.target.name] = event.target.value; // replace e.target.value with whatever you want to change it to
        this.setState(newArr);
    };


    handleEdit = async (event) => {
        event.preventDefault();
        const c_name = this.state.consignmentInfo.c_name;
        const shipping_charge = this.state.consignmentInfo.shipping_charge;
        const pkgs = this.state.consignmentInfo.pkgs;
        const cbm = this.state.consignmentInfo.cbm;
        const label = this.state.consignmentInfo.label;
        // const warehouse = this.state.consignmentInfo.warehouse.id;
        // const customer = this.state.consignmentInfo.customer.id;
        // const price_category = this.state.consignmentInfo.price_category.id;
        const user = localStorage.getItem('user_id');
        const id = this.state.consignmentInfo.id;

        axios.patch(`${LOCAL_URL}cargo/api/consignments/${id}/`, {
            name: c_name,
            shipping_charge: shipping_charge,
            pkgs: pkgs,
            cbm: cbm,
            label: label,
            // warehouse: warehouse,
            // customer: customer,
            // price_category: price_category,
            user: user
        }).then((res) => {
            alertService.info('Success! ' + res.data.name + ' consignment edited successfully ', {
                autoClose: true,
                keepAfterRouteChange: false
            })
        }).catch(error => console.error(error));

        this.setState({open: false})
    }

    buildTableBody(data, columns, customer_name, customer_phone, sum_pkgs, sum_cbm, sum_amount) {
        let body = [];
        const rowData1 = [
            {
                text: 'NAME: ' + customer_name,
                border: [true, true, true, true],
                style: 'tableHeader',
                colSpan: 4
            }, {}, {}, {},
            {
                text: 'TOTAL PKGS: ' + sum_pkgs,
                border: [true, true, true, true],
                style: 'tableHeader',
                colSpan: 4
            }, {}, {}, {}
        ]
        const rowData2 = [
            {text: 'TEL: ' + customer_phone, style: 'tableHeader', colSpan: 4}, {}, {}, {},
            {text: 'PAYMENT IV NO: ', style: 'tableHeader', colSpan: 4}, {}, {}, {}
        ]
        const rowData3 = [
            {text: 'CBM: ' + sum_cbm, style: 'tableHeader', colSpan: 4}, {}, {}, {},
            {text: 'TOTAL AMOUNT: USD ' + sum_amount, style: 'tableHeader', colSpan: 4}, {}, {}, {}
        ]
        body.push(rowData1);
        body.push(rowData2);
        body.push(rowData3);
        body.push(columns);

        data.forEach(function (row) {
            let dataRow = [];

            columns.forEach(function (column) {
                dataRow.push(row[column]);
            })

            body.push(dataRow);
        });

        return body;
    }

    table(data, columns, customer_name, customer_phone, sum_pkgs, sum_cbm, sum_amount) {
        return {
            table: {
                headerRows: 1,
                style: 'consignmentTable',
                widths: [35, 60, 35, 50, '*', 60, 35, 60],
                body: this.buildTableBody(data, columns, customer_name, customer_phone, sum_pkgs, sum_cbm, sum_amount)
            }
        };
    }

    handleGenerateListPdf = async () => {
        const removeDuplicates = (duplicates) => {
            const flag = {};
            const unique = [];
            duplicates.forEach(elem => {
                if (!flag[elem.consignment.id]) {
                    flag[elem.consignment.id] = true;
                    unique.push(elem);
                }
            });
            return unique;
        }
        let sum = a => a.reduce((x, y) => x + y);
        axios.get(`${LOCAL_URL}cargo/api/all_good_consignments/`).then((response) => {
            const r = response.data;
            if (r.length > 0) {
                //
                let flags = [], output = [], l = r.length, i;
                for (i = 0; i < l; i++) {
                    if (flags[r[i].customer.id]) continue;
                    flags[r[i].customer.id] = true;
                    output.push(r[i].customer.id);
                }
                let docDefinition = {
                    content: [
                        {
                            text: 'ALL CONSIGNMENTS LIST',
                            style: 'header',
                            alignment: 'center'
                        },
                    ],
                    styles: {
                        header: {
                            fontSize: 14,
                            bold: true,
                            margin: [0, 0, 0, 10]
                        },
                        subheader: {
                            fontSize: 12,
                            bold: true,
                            margin: [0, 10, 0, 5]
                        },
                        quote: {
                            italics: true
                        },
                        small: {
                            fontSize: 11
                        },
                        consignmentTable: {
                            margin: [0, 5, 0, 0]
                        },
                        tableHeader: {
                            bold: true,
                            fontSize: 12,
                            color: 'black'
                        }

                    }
                }

                //push tables
                output.forEach((customer) => {
                    let consignmentDataRows = [];
                    let same_consignment = [];
                    let customer_name = '';
                    let customer_phone = '';
                    let sum_pkgs
                    let sum_cbm
                    let sum_amount

                    const data_filter = r.filter(element => element.customer.id === customer);

                    const unique_consignment = removeDuplicates(data_filter);
                    sum_pkgs = (sum(unique_consignment.map(x => Number(x.consignment.pkgs)))).toLocaleString();
                    sum_cbm = (sum(unique_consignment.map(x => Number(x.consignment.cbm)))).toLocaleString();
                    sum_amount = (sum(unique_consignment.map(x => Number(x.consignment.shipping_charge)))).toLocaleString();
                    // Get Same Consignments
                    // Array of consignment ids
                    const unique = [...new Set(data_filter.map(item => item.consignment.id))];
                    unique.forEach((item_consignment) => {
                        const consignment_filter = data_filter.filter(
                            element => element.consignment.id === item_consignment);
                        same_consignment.push(consignment_filter);
                    })
                    same_consignment.forEach(consignment_goods => {
                        for (let i = 0; i < consignment_goods.length; i++) {
                            customer_name = consignment_goods[i].customer.firstname;
                            customer_phone = consignment_goods[i].customer.cellphone;
                            consignmentDataRows.push(
                                {
                                    PKGS: {
                                        text: consignment_goods[i].consignment.pkgs,
                                        rowSpan: consignment_goods.length,
                                        style: 'small'
                                    },
                                    BN: {
                                        text: consignment_goods[i].consignment.label,
                                        rowSpan: consignment_goods.length,
                                        style: 'small'
                                    },
                                    QTY: {text: consignment_goods[i].quantity, style: 'small'},
                                    UNIT: {text: consignment_goods[i].unit, style: 'small'},
                                    DESCRIPTION: {text: consignment_goods[i].good.name, style: 'small'},
                                    RECEIPT: {text: consignment_goods[i].good.supplier_receipt_no, style: 'small'},
                                    CBM: {
                                        text: consignment_goods[i].consignment.cbm,
                                        rowSpan: consignment_goods.length,
                                        style: 'small'
                                    },
                                    AMOUNT: {
                                        text: consignment_goods[i].consignment.shipping_charge,
                                        rowSpan: consignment_goods.length,
                                        style: 'small'
                                    },
                                }
                            )
                        }
                    });

                    docDefinition.content.push(
                        this.table(consignmentDataRows,
                            ['PKGS',
                                'BN',
                                'QTY',
                                'UNIT',
                                'DESCRIPTION',
                                'RECEIPT',
                                'CBM',
                                'AMOUNT'
                            ],
                            customer_name,
                            customer_phone,
                            sum_pkgs,
                            sum_cbm,
                            sum_amount)
                    );
                });
                console.log(docDefinition)
                pdfMake.createPdf(docDefinition).download(`all_consignments_list.pdf`);

            } else {
                alertService.warn('Sorry, no data available we can not generate this list.', {
                    autoClose: true,
                    keepAfterRouteChange: false
                })
            }
        });
    };

    handleGenerateAllPendingPdf = async () => {
        const removeDuplicates = (duplicates) => {
            const flag = {};
            const unique = [];
            duplicates.forEach(elem => {
                if (!flag[elem.consignment.id]) {
                    flag[elem.consignment.id] = true;
                    unique.push(elem);
                }
            });
            return unique;
        }
        let sum = a => a.reduce((x, y) => x + y);
        axios.get(`${LOCAL_URL}cargo/api/all_good_consignments/`).then((response) => {
            const res = response.data;
            const r = res.filter(element => element.container === null);

            if (r.length > 0) {
                //
                let flags = [], output = [], l = r.length, i;
                for (i = 0; i < l; i++) {
                    if (flags[r[i].customer.id]) continue;
                    flags[r[i].customer.id] = true;
                    output.push(r[i].customer.id);
                }
                let docDefinition = {
                    content: [
                        {
                            text: 'PENDING CONSIGNMENTS LIST',
                            style: 'header',
                            alignment: 'center'
                        },
                    ],
                    styles: {
                        header: {
                            fontSize: 14,
                            bold: true,
                            margin: [0, 0, 0, 10]
                        },
                        subheader: {
                            fontSize: 12,
                            bold: true,
                            margin: [0, 10, 0, 5]
                        },
                        quote: {
                            italics: true
                        },
                        small: {
                            fontSize: 11
                        },
                        consignmentTable: {
                            margin: [0, 5, 0, 0]
                        },
                        tableHeader: {
                            bold: true,
                            fontSize: 12,
                            color: 'black'
                        }

                    }
                }

                //push tables
                output.forEach((customer) => {
                    let consignmentDataRows = [];
                    let same_consignment = [];
                    let customer_name = '';
                    let customer_phone = '';
                    let sum_pkgs
                    let sum_cbm
                    let sum_amount

                    const data_filter = r.filter(element => element.customer.id === customer);

                    const unique_consignment = removeDuplicates(data_filter);
                    sum_pkgs = (sum(unique_consignment.map(x => Number(x.consignment.pkgs)))).toLocaleString();
                    sum_cbm = (sum(unique_consignment.map(x => Number(x.consignment.cbm)))).toLocaleString();
                    sum_amount = (sum(unique_consignment.map(x => Number(x.consignment.shipping_charge)))).toLocaleString();
                    // Get Same Consignments
                    // Array of consignment ids
                    const unique = [...new Set(data_filter.map(item => item.consignment.id))];
                    unique.forEach((item_consignment) => {
                        const consignment_filter = data_filter.filter(
                            element => element.consignment.id === item_consignment);
                        same_consignment.push(consignment_filter);
                    })
                    same_consignment.forEach(consignment_goods => {
                        for (let i = 0; i < consignment_goods.length; i++) {
                            customer_name = consignment_goods[i].customer.firstname;
                            customer_phone = consignment_goods[i].customer.cellphone;
                            consignmentDataRows.push(
                                {
                                    PKGS: {
                                        text: consignment_goods[i].consignment.pkgs,
                                        rowSpan: consignment_goods.length,
                                        style: 'small'
                                    },
                                    BN: {
                                        text: consignment_goods[i].consignment.label,
                                        rowSpan: consignment_goods.length,
                                        style: 'small'
                                    },
                                    QTY: {text: consignment_goods[i].quantity, style: 'small'},
                                    UNIT: {text: consignment_goods[i].unit, style: 'small'},
                                    DESCRIPTION: {text: consignment_goods[i].good.name, style: 'small'},
                                    RECEIPT: {text: consignment_goods[i].good.supplier_receipt_no, style: 'small'},
                                    CBM: {
                                        text: consignment_goods[i].consignment.cbm,
                                        rowSpan: consignment_goods.length,
                                        style: 'small'
                                    },
                                    AMOUNT: {
                                        text: consignment_goods[i].consignment.shipping_charge,
                                        rowSpan: consignment_goods.length,
                                        style: 'small'
                                    },
                                }
                            )
                        }
                    });

                    docDefinition.content.push(
                        this.table(consignmentDataRows,
                            ['PKGS',
                                'BN',
                                'QTY',
                                'UNIT',
                                'DESCRIPTION',
                                'RECEIPT',
                                'CBM',
                                'AMOUNT'
                            ],
                            customer_name,
                            customer_phone,
                            sum_pkgs,
                            sum_cbm,
                            sum_amount)
                    );
                });
                console.log(docDefinition)
                pdfMake.createPdf(docDefinition).download(`pending_consignments_list.pdf`);

            } else {
                alertService.warn('Sorry, no data available we can not generate this list.', {
                    autoClose: true,
                    keepAfterRouteChange: false
                })
            }
        });
    };


    render() {
        const cs = customStyle;
        const {
            openView,
            detailView,
            open,
            deleteView,
            consignments,
            consignmentDetails,
            previous,
            next,
            loading,
            loading_data,
            searchTerm,
            consignmentInfo
        } = this.state;

        const handleClick = (consignment) => {
            this.setState({open: true, consignmentInfo: consignment});
        };

        const handleDeleteView = (consignment) => {
            this.setState({deleteView: true, consignmentInfo: consignment})
        }

        const handleView = (consignment) => {
            this.setState({openView: true, consignmentInfo: consignment});
        };
        const renderShippingStatus = (params) => {
            if (params.value === 0) {
                return <span>
                            <Chip size="small" label="Booked"
                                  color="primary"/>
                            </span>;
            } else if (params.value === 1) {
                return <span>
                             <Chip size="small" label="Loaded"
                                   color="primary" style={{backgroundColor: "teal"}}/>
                            </span>;
            } else if (params.value === 2) {
                return <span>
                             <Chip size="small" label="Shipped"
                                   color="primary" style={{backgroundColor: "blue"}}/>
                            </span>;
            } else if (params.value === 3) {
                return <span>
                             <Chip size="small" label="Port"
                                   color="primary" style={{backgroundColor: "green"}}/>
                            </span>;
            } else if (params.value === 4) {
                return <span>
                             <Chip size="small" label="Arrived"
                                   color="primary" style={{backgroundColor: "green"}}/>
                            </span>;
            } else if (params.value === 5) {
                return <span>
                             <Chip size="small" label="Canceled"
                                   color="primary" style={{backgroundColor: "red"}}/>
                            </span>;
            }
        }
        const
            columns = [
                {field: 'id', headerName: 'ID', width: 90, hide: true},
                {
                    field: 'item',
                    headerName: 'Item',
                    width: 200,
                },
                {
                    field: 'supplier_no',
                    headerName: 'Receipt No',
                    width: 200,
                },
                {
                    field: 'label',
                    headerName: 'Label',
                    width: 130,
                },
                {
                    field: 'pkgs',
                    headerName: 'PKGS',
                    width: 130,
                },
                {
                    field: 'quantity',
                    headerName: 'Quantity',
                    width: 130,
                },
                {
                    field: 'cbm',
                    headerName: 'CBM',
                    width: 130,
                },
                {
                    field: 'shipping',
                    headerName: 'Cost',
                    width: 130,
                },
                {
                    field: 'container_no',
                    headerName: 'Container No',
                    width: 250,
                },
                {
                    field: 'shipping_line',
                    headerName: 'Shipping Line',
                    width: 250,
                },
                {
                    field: 'shipping_date',
                    headerName: 'Shipping Date',
                    type: 'date',
                    width: 250,
                },
                {
                    field: 'arrival_date',
                    headerName: 'Arrival Date',
                    type: 'date',
                    width: 250,
                },

                {
                    field: 'shipping_status',
                    headerName: 'Status',
                    sortable: false,
                    width: 200,
                    renderCell: renderShippingStatus,
                },
            ];
        const handleDetails = (consignment) => {
            this.setState({
                loading_data: true,
                detailView: true,
                consignmentInfo: consignment,
            });
            const consignment_id = consignment.id;
            axios.get(`${LOCAL_URL}cargo/api/get_consignment_goods/?c=` + consignment_id).then((response) => {
                const r = response.data;
                if (r.length > 0) {
                    const consignment_details = r.map(d => ({
                        "id": d.id,
                        "item": d.consignment.name,
                        "supplier_no": d.good.supplier_receipt_no,
                        "label": d.consignment.label,
                        "pkgs": d.consignment.pkgs,
                        "quantity": d.quantity,
                        "cbm": d.consignment.cbm,
                        "shipping": d.consignment.shipping_charge,
                        "container_no": d.container ? d.container.container_no : '',
                        "shipping_line": d.container ? d.container.shipping_line : '',
                        "shipping_date": d.container ? d.container.shipping_date : '',
                        "arrival_date": d.container ? d.container.arrival_date : '',
                        "shipping_status": d.container ? d.container.shipping_status : '',
                    }))
                    this.setState({
                        consignmentDetails: consignment_details,
                        loading_data: false,
                    })
                } else {
                    this.setState({
                        loading_data: false,
                    })
                }
            });

        };
        const handleClose = () => {
            this.setState({
                open: false,
                openView: false,
                deleteView: false,
                detailView: false,
                consignmentDetails: [],
            })
        };

        const {classes} = this.props;

        const renderDate = (consignment) => {
            const created = consignment.created_date;
            return created.substr(0, created.indexOf('T'));
        }

        const renderStatus = (consignment) => {
            if (consignment.delivery_status === 1) {
                return <span>
                     <Chip size="small" label="Loaded"
                           color="primary"/>
                </span>;
            } else if (consignment.delivery_status === 0) {
                return <span>
                     <Chip size="small" label="Pending"
                           color="secondary"/>
                </span>;
            } else if (consignment.delivery_status === 2) {
                return <span>
                     <Chip size="small" label="Delivered"
                           color="primary"/>
                </span>;
            } else if (consignment.delivery_status === 3) {
                return <span>
                     <Chip size="small" label="Canceled"
                           color="secondary"/>
                </span>;
            }
        }

        return (
            <div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 ">
                            <div className="header mt-md-5">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h1 className="header-title">
                                                <i className="fe fe-package"/>&nbsp;&nbsp;&nbsp;
                                                All Consignments
                                            </h1>
                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/consignments/new'}>
                                                    <Button variant="contained" startIcon={<AddIcon/>} color='primary'>Add
                                                        Consignment</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Button color="primary"
                                                        onClick={() => this.handleGenerateListPdf()}>ALL</Button>
                                                &nbsp;&nbsp;&nbsp;
                                                <Button color="secondary"
                                                        onClick={() => this.handleGenerateAllPendingPdf()}>PENDING</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 ">
                            {loading ? (
                                <div className='text-center'>
                                    <div className='spinner-border text-primary align-content-center' role='status'>
                                        {' '}
                                        <span className='sr-only'>Loading...</span>{' '}
                                    </div>
                                </div>
                            ) : (
                                <div className="content">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <input
                                                name="searchTerm"
                                                id="searchTerm"
                                                type="text"
                                                placeholder="Tracking No/ Customer"
                                                value={searchTerm}
                                                onChange={this.handleChange}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <button onClick={this.searchPage}>Search
                                            </button>
                                        </div>
                                        <div className='col-auto'>
                                            <div className="pagination">
                                                <button disabled={previous === null} onClick={this.prevPage}>Previous
                                                </button>
                                                &nbsp;&nbsp;
                                                <button disabled={next === null} onClick={this.nextPage}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <TableContainer className={classes.container} component={Paper}>
                                        <Table stickyHeader className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>Item</TableCell>
                                                    <TableCell>Label</TableCell>
                                                    <TableCell>Customer</TableCell>
                                                    <TableCell>Tracking No</TableCell>
                                                    <TableCell>PKGS</TableCell>
                                                    <TableCell>CBM</TableCell>
                                                    <TableCell>Cost</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {consignments.map((consignment) => {
                                                    return <>
                                                        <TableRow key={consignment.id}>
                                                            <TableCell>{renderDate(consignment)}</TableCell>
                                                            <TableCell>{consignment.name}</TableCell>
                                                            <TableCell>{consignment.label}</TableCell>
                                                            <TableCell>{consignment.customer.firstname}</TableCell>
                                                            <TableCell>{consignment.tracking_number}</TableCell>
                                                            <TableCell>{consignment.pkgs}</TableCell>
                                                            <TableCell>{consignment.cbm}</TableCell>
                                                            <TableCell>{consignment.shipping_charge}</TableCell>
                                                            <TableCell>{renderStatus(consignment)}</TableCell>
                                                            <TableCell>
                                                                <Tooltip title="Consignment Details">
                                                                    <IconButton aria-label="view"
                                                                                onClick={() => handleDetails(consignment)}>
                                                                        <VisibilityIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="View">
                                                                    <IconButton aria-label="view"
                                                                                onClick={() => handleView(consignment)}>
                                                                        <InfoIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Edit">
                                                                    <IconButton aria-label="update"
                                                                                onClick={() => handleClick(consignment)}>
                                                                        <EditIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Delete">
                                                                    <IconButton aria-label="update"
                                                                                onClick={() => handleDeleteView(consignment)}>
                                                                        <DeleteIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <Dialog
                        open={deleteView}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <form onSubmit={this.handleDelete} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Delete Consignment</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete this {consignmentInfo.name}?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    No
                                </Button>
                                <Button type='submit' color="primary">
                                    Yes
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <form onSubmit={this.handleEdit} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Edit Consignment</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    To edit to this consignment, please edit the fields below.
                                </DialogContentText>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_name">Name</label>
                                            <input
                                                type='text'
                                                name='c_name'
                                                className='form-control'
                                                required='required'
                                                value={open ? consignmentInfo.name : ""}
                                                id='id_c_name'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_warehouse">Warehouse</label>
                                            <input
                                                type='text'
                                                name='warehouse'
                                                disabled='true'
                                                className='form-control'
                                                required='required'
                                                value={open ? consignmentInfo.warehouse.name : ""}
                                                id='id_warehouse'
                                                onChange={this.handleChangeEdit}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_label">Label</label>
                                            <input
                                                type='text'
                                                name='label'
                                                className='form-control'
                                                required='required'
                                                value={open ? consignmentInfo.label : ""}
                                                id='id_label'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_pkgs">PKGS</label>
                                            <input
                                                type='text'
                                                name='pkgs'
                                                className='form-control'
                                                required='required'
                                                value={open ? consignmentInfo.pkgs : ""}
                                                id='id_pkgs'
                                                onChange={this.handleChangeEdit}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_customer">Customer</label>
                                            <input
                                                type='text'
                                                name='name'
                                                className='form-control'
                                                required='required'
                                                disabled='true'
                                                value={open ? consignmentInfo.customer.firstname : ""}
                                                id='id_customer'
                                                onChange={this.handleChangeEdit}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_cbm">Cubic Metre (CBM)</label>
                                            <input
                                                type='number'
                                                name='cbm'
                                                className='form-control'
                                                required='required'
                                                value={open ? consignmentInfo.cbm : ""}
                                                id='id_cbm'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_price_category">Price Category</label>
                                            <input
                                                type='number'
                                                name='price_category'
                                                disabled='true'
                                                className='form-control'
                                                value={open ? consignmentInfo.price_category.name : ""}
                                                id='id_price_category'
                                                onChange={this.handleChangeEdit}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_supplier">Shipping Cost</label>
                                            <input
                                                type='number'
                                                name='shipping_charge'
                                                className='form-control'
                                                required='required'
                                                value={open ? consignmentInfo.shipping_charge : ""}
                                                id='id_shipping_charge'
                                                onChange={this.handleChangeEdit}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    Cancel
                                </Button>
                                <Button type='submit' color="primary">
                                    Edit
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={openView}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <DialogTitle id="form-dialog-title">Received Goods</DialogTitle>
                        <DialogContent>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Name:</strong> {openView ? consignmentInfo.name : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Tracking No:</strong> {openView ? consignmentInfo.tracking_number : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Warehouse:</strong> {openView ? consignmentInfo.warehouse.name : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Customer:</strong> {openView ? consignmentInfo.customer.firstname + " " + consignmentInfo.customer.lastname : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Label:</strong> {openView ? consignmentInfo.label : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Cubic Meter:</strong> {openView ? consignmentInfo.cbm : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>PKGS:</strong> {openView ? consignmentInfo.pkgs : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Price
                                            Category:</strong> {openView ? consignmentInfo.price_category.name : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Shipping
                                            Cost:</strong> {openView ? consignmentInfo.shipping_charge : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Created
                                            By:</strong> {openView ? consignmentInfo.user.first_name + " " + consignmentInfo.user.last_name : ""}
                                    </Typography>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        fullScreen
                        open={detailView}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                        aria-labelledby="form-dialog-title">
                        <AppBar className={cs.appBar}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                    <CloseIcon/>
                                </IconButton>
                                <Typography variant="h6" className={cs.title}>
                                    {detailView ? consignmentInfo.customer.firstname + " " + consignmentInfo.name + " " + consignmentInfo.tracking_number : ""}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <div className='pd-top'>
                                <div className="pb-2">
                                    <Alert severity="info">
                                        Consignment details are below!
                                    </Alert>
                                </div>
                                {loading_data ? (
                                    <div className='text-center'>
                                        <div className='spinner-border text-primary align-content-center'
                                             role='status'>
                                            {' '}
                                            <span className='sr-only'>Loading...</span>{' '}
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div style={{height: 400, width: '100%'}}>
                                            <DataGrid
                                                getRowId={(r) => r.id}
                                                rows={consignmentDetails}
                                                columns={columns}
                                                pageSize={5}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>

        )
    }
}

export default withStyles(useStyles)(ListConsignment);
