import React, {Component} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Redirect} from "react-router";
import HomeIcon from '@material-ui/icons/Home';
import Button from '@material-ui/core/Button';
import {alertService} from "../_services/alert.service";
import GoodList from "./goodList";
import AsyncSelect from "react-select/async";
import {LOCAL_URL} from "../utils";

class CreateGoodPage extends Component {
    componentDidMount() {
        let user_id = localStorage.getItem('user_id');
        let access_token = localStorage.getItem("token")
        this.setState({id: user_id})
        this.setState({token: access_token})
        this.getCustomer().then()
        this.getSupplier().then()
    }

    state = {
        supplier_id: "",
        supplier_name: "",
        warehouse_id: "",
        customer_id: "",
        customer_name: "",
        warehouse_name: "",
        supplier_receipt_no: "",
        supplier: "",
        warehouse: "",
        customer: "",
        selectWarehouses: [],
        selectCustomers: [],
        selectSupplier: [],
        goodList: [],
        errorMessage: "",
        autoClose: false,
        keepAfterRouteChange: false,
        redirectToNewPage: false,
        goodDetails: [
            {
                index: Math.random(),
                name: "",
                pckg: "",
                quantity: "",
                unit: ""
            }
        ]
    };
    handleChange = (event) => {
        console.log('Name: ', event.target.name)
        this.setState({[event.target.name]: event.target.value});
    };

    // handleSelectWarehouse(e) {
    //     this.setState({warehouse_id: e.value, warehouse_name: e.label})
    // };

    handleSelectCustomer(e) {
        this.setState({customer_id: e.value, customer_name: e.label})
    };

    loadOptions = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        axios.get(`${LOCAL_URL}cargo/api/list_customers/?search=${inputValue}`).then(response => {
                const json = response.data.results
                callback(json.map(i => ({
                    label: i.cellphone + " "+ i.firstname + " " + i.lastname,
                    value: i.id
                })))
            }
        );
    }

    loadSuppliers = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        axios.get(`${LOCAL_URL}cargo/api/list_suppliers/?search=${inputValue}`).then(response => {
                const json = response.data.results
                callback(json.map(i => ({
                    label: i.name,
                    value: i.id
                })))
            }
        );
    }

    handleSelectSupplier(e) {
        this.setState({supplier_id: e.value, supplier_name: e.label})
    };

    handleChangeGoodList = (e) => {
        if (
            ["name", "pckg", "quantity", "unit"].includes(
                e.target.name
            )
        ) {
            let goodDetails = [...this.state.goodDetails];
            goodDetails[e.target.dataset.id][e.target.name] = e.target.value;
            this.setState({goodDetails});
        } else {
            this.setState({[e.target.name]: e.target.value});
        }
    };
    addNewRow = () => {
        this.setState(prevState => ({
            goodDetails: [
                ...prevState.goodDetails,
                {
                    index: Math.random(),
                    name: "",
                    pckg: "",
                    quantity: "",
                    unit: ""
                }
            ]
        }));
    };

    deleteRow = index => {
        this.setState({
            goodDetails: this.state.goodDetails.filter(
                (s, sindex) => index !== sindex
            )
        });
    };

    clickOnDelete(record) {
        this.setState({
            goodDetails: this.state.goodDetails.filter(r => r !== record)
        });
    }

    handleSubmitGood = async (event) => {
        event.preventDefault();
        let new_unit = '';
        let new_warehouse = 0 ;
        const goodList = this.state.goodDetails;
        const supplier_receipt_no = this.state.supplier_receipt_no;
        const warehouse = this.state.warehouse;
        const customer = this.state.customer_id;
        const supplier = this.state.supplier_id;
        const user = localStorage.getItem('user_id');
        // console.log(goodList, supplier_receipt_no, warehouse)
        goodList.forEach((good) => {
            if (warehouse ===""){
                new_warehouse = "2";
            } else if (warehouse!== ""){
                new_warehouse = warehouse
            }
            if (good.unit === ""){
                new_unit = "pieces"
            } else if (good.unit !== ""){
                new_unit = good.unit
            }
            axios.post(`${LOCAL_URL}cargo/api/goods/`, {
                name: good.name,
                quantity: good.quantity,
                unit: new_unit,
                pckg: good.pckg,
                supplier_receipt_no: supplier_receipt_no,
                warehouse: new_warehouse,
                customer: customer,
                supplier: supplier,
                user: user,
                status: 0
            }).then(res => {
                    if (res.data.status === 0) {
                        this.setState({redirectToNewPage: true});
                        alertService.success('Success! Good Received successfully', {
                            autoClose: true,
                            keepAfterRouteChange: false
                        })
                    }
                }
            ).catch((error) => {
                this.setState({errorMessage: error.message})
                console.log(error)
                alertService.error('Error :( ' + error.message, {autoClose: true, keepAfterRouteChange: false})
            });

        });


    };

    // async getWarehouse() {
    //     axios.get(`/cargo/api/all_warehouses/`)
    //         .then(r => {
    //             const warehouseList = r.data;
    //             const warehouses = warehouseList.map(d => ({
    //                 "value": d.id,
    //                 "label": d.name
    //             }))
    //             this.setState({selectWarehouses: warehouses})
    //         })
    // }

    async getCustomer() {
        axios.get(`${LOCAL_URL}cargo/api/all_customers/`)
            .then(r => {
                const customerList = r.data;
                const customers = customerList.map(d => ({
                    "value": d.id,
                    "label": d.firstname + " " + d.lastname + " " + d.cellphone
                }))
                this.setState({selectCustomers: customers})
            })
    }

    async getSupplier() {
        axios.get(`${LOCAL_URL}cargo/api/all_suppliers/`)
            .then(r => {
                const supplierList = r.data;
                const supplier = supplierList.map(d => ({
                    "value": d.id,
                    "label": d.name
                }))
                this.setState({selectSupplier: supplier})
            })
    }

    render() {
        const {supplier_receipt_no, goodDetails, warehouse} = this.state;
        if (this.state.redirectToNewPage) {
            return (
                <Redirect to="/goods"/>
            )
        }
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12">
                            <div className="header mt-md-3">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">

                                            <h1 className="header-title">
                                                <i className='fe fe-codepen'/> &nbsp;&nbsp;&nbsp;
                                                Receive Goods
                                            </h1>

                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/goods'}>
                                                    <Button variant="contained"
                                                            startIcon={<i className='fe fe-codepen'/>}>
                                                        Goods Received</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <form onSubmit={this.handleSubmitGood} onChange={this.handleChangeGoodList}
                                  className="organizer-form">
                                <div className='row'>
                                    <div className='col-12 col-md-3 col-xl-3'>
                                        <div className="form-group">
                                            <label htmlFor="id_customer">Customer</label>
                                            <AsyncSelect
                                                onChange={this.handleSelectCustomer.bind(this)}
                                                placeholder={'type something ...'}
                                                loadOptions={this.loadOptions}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 col-xl-3'>
                                        <div className="form-group">
                                            <label htmlFor="id_supplier">Supplier</label>
                                            <AsyncSelect
                                                onChange={this.handleSelectSupplier.bind(this)}
                                                placeholder={'type something ...'}
                                                loadOptions={this.loadSuppliers}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 col-xl-3'>
                                        <div className="form-group">
                                            <label htmlFor="id_supplier_receipt_no">Supplier Receipt No</label>
                                            <input
                                                type='text'
                                                name='supplier_receipt_no'
                                                placeholder='ex: 0011567'
                                                className='form-control'
                                                required='required'
                                                value={supplier_receipt_no}
                                                id='id_supplier_receipt_no'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 col-xl-3'>
                                        <div className="form-group">
                                            <label htmlFor="id_warehouse">Warehouse</label>
                                            <select name="warehouse" className="required form-control" required="required"
                                                    id="id_warehouse" value={warehouse}  onChange={this.handleChange}>
                                                <option value="2">WAREHOUSE CHINA</option>
                                                <option value="3">WAREHOUSE DUBAI</option>
                                            </select>
                                            {/*<Select*/}
                                            {/*    options={this.state.selectWarehouses}*/}
                                            {/*    onChange={this.handleSelectWarehouse.bind(this)}/>*/}
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <GoodList
                                    add={this.addNewRow}
                                    delete={this.clickOnDelete.bind(this)}
                                    goodDetails={goodDetails}
                                />
                                <button
                                    className='primaryAction btn btn-lg btn-block btn-primary mb-3'
                                    type='submit'
                                >
                                    Save Goods Received
                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CreateGoodPage;