import { connect } from "react-redux";
import {Redirect} from 'react-router-dom'
import React, { Component } from "react";
import { authLogin} from "../actions/auth";

class LoginPage extends Component {
    state = {
    email: "",
    password: ""
  };

    handleChange =  (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

    handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    this.props.login(email, password);
  };

    render() {
        const { error, token } = this.props;
        const { email, password } = this.state;
        if (token) {
          return <Redirect to="/" />;
        }
        return (
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-12 col-md-5 col-xl-4 my-5'>
                    {error && <p>{this.props.error.message}</p>}
                  <h1>Sign In</h1>
                  <form className='login' onSubmit={this.handleSubmit}>
                    <div className='form-group'>
                      <label htmlFor='id_login'>E-mail</label>
                      <input
                        type='email'
                        name='email'
                        placeholder='E-mail address'
                        className='form-control'
                        required=''
                        value={email}
                        id='id_login'
                        onChange={this.handleChange}
                      />
                      <div className='invalid-feedback'/>
                    </div>
                    <div className='form-group'>
                      <div className='row'>
                        <div className='col'>
                          <label htmlFor='id_password'>Password</label>
                        </div>
                        <div className='col-auto'>
                          <a
                            href='/accounts/password/reset/'
                            className='form-text small text-muted'
                          >
                            Forgot Password?
                          </a>
                        </div>
                      </div>

                      <div className='input-group input-group-merge'>
                        <input
                          type='password'
                          name='password'
                          placeholder='Password'
                          className='form-control form-control-appended'
                          required=''
                          value={password}
                          id='id_password'
                          onChange={this.handleChange}
                        />
                        <div className='input-group-append'>
                          <span className='input-group-text'>
                            <i className='fe fe-eye'/>
                          </span>
                        </div>
                      </div>
                    </div>

                    <button
                      className='primaryAction btn btn-lg btn-block btn-primary mb-3'
                      type='submit'
                    >
                      Sign In
                    </button>
                    <div className='text-center'>
                      <small className='text-muted text-center'>
                        Don"t have an account yet?{' '}
                        <a href='/accounts/signup/?next=%2F'>Sign up </a>.
                      </small>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
  return {
    error: state.auth.error,
    token: state.auth.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (email, password) => dispatch(authLogin(email, password))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
