import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Redirect} from "react-router";
import axios from "axios";
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import {alertService} from "../_services/alert.service";
import AsyncSelect from "react-select/async";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane, faSms} from '@fortawesome/free-solid-svg-icons';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';

class CreateWhatsappMessagePage extends Component {
    state = {
        customer_id: "",
        customer_name: "",
        customer_cellphone: "",
        amount: "",
        unit: "",
        quantity: "",
        message: "",
        errorMessage: "",
        autoClose: false,
        keepAfterRouteChange: false,
        redirectToNewPage: false
    };

    loadOptions = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        axios.get(`https://gnmcargo.com/cargo/api/list_customers/?search=${inputValue}`).then(response => {
                const json = response.data.results
                callback(json.map(i => ({
                    label: i.firstname + " " + i.cellphone,
                    value: i.id,
                    name: i.firstname,
                    cellphone: i.cellphone
                })))
            }
        );
    }

    handleChange = (event) => {
        let MyResponse = 'Habari ' + this.state.customer_name + ', tunapenda kukumbusha kujianda kwa malipo ya mzigo' +
            ' wako wenye ' + this.state.quantity + ' ' + this.state.unit + ' kwa gharama ya ' + this.state.amount + 'USD.' +
            ' Ofisi: Msimbazi Kkoo mkabala na AZANIA BANK. Asante kwa kuichagua GNMCARGO.  ';
        this.setState({[event.target.name]: event.target.value, message: MyResponse});
    };

    handleSelectCustomer = (e) => {
        this.setState({customer_id: e.value, customer_name: e.name, customer_cellphone: e.cellphone})
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const MyRequest = "Bill"
        const customer_name = this.state.customer_name
        const customer_id = this.state.customer_id
        const sender_id = this.state.customer_cellphone
        const message = this.state.message
        axios.post('https://gnmcargo.com/cargo/api/whatsapp/', {
            customer_id: customer_id,
            customer_name: customer_name,
            sender_id: sender_id,
            request: MyRequest,
            response: message,
            status: 0,
            type: "Y",
        }).then(
            () => {
                alertService.success('Success! whatsapp successfully sent', {
                    autoClose: true,
                    keepAfterRouteChange: true
                })
            }
        ).catch((error) => {
            console.error(error);
        });
        this.setState({redirectToNewPage: true});
    };

    componentDidMount() {
        let user_id = localStorage.getItem('user_id');
        let access_token = localStorage.getItem("token")
        this.setState({id: user_id})
        this.setState({token: access_token})
    }

    render() {
        const {
            unit,
            amount,
            quantity,
            message
        } = this.state;
        if (this.state.redirectToNewPage) {
            return (
                <Redirect to="/whatsapp"/>
            )
        }
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 col-xl-12">

                            <div className="header mt-md-3">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">

                                            <h1 className="header-title">
                                                <FontAwesomeIcon icon={faWhatsapp}/>&nbsp;&nbsp;&nbsp;
                                                Create Quick Message
                                            </h1>

                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/whatsapp'}>
                                                    <Button variant="contained"
                                                            startIcon={<FontAwesomeIcon icon={faWhatsapp}/>}>
                                                        All WhatsApp Messages</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <form onSubmit={this.handleSubmit} className="organizer-form">
                                <div className='row'>
                                    <div className='col-12 col-md-4 col-xl-4'>
                                        <div className="form-group">
                                            <label htmlFor="id_customer">Customer</label>
                                            <AsyncSelect
                                                onChange={this.handleSelectCustomer.bind(this)}
                                                placeholder={'type something ...'}
                                                loadOptions={this.loadOptions}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-2 col-xl-2'>
                                        <div className="form-group">
                                            <label htmlFor="unit">Unit</label>
                                            <select name="unit" className="required form-control" required="required"
                                                    id="unit" value={unit} onChange={this.handleChange}>
                                                <option value="" selected="">---------</option>
                                                <option value="Pkgs">Packages</option>
                                                <option value="Bale">Bale</option>
                                            </select>
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 col-xl-3'>
                                        <div className="form-group">
                                            <label htmlFor="quantity">Quantity</label>
                                            <input
                                                type='text'
                                                name='quantity'
                                                placeholder='ex: 1'
                                                className='form-control'
                                                required='required'
                                                value={quantity}
                                                id='quantity'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 col-xl-3'>
                                        <div className="form-group">
                                            <label htmlFor="amount">Amount</label>
                                            <input
                                                type='text'
                                                name='amount'
                                                placeholder='ex: 100'
                                                className='form-control'
                                                required='required'
                                                value={amount}
                                                id='amount'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-12 col-xl-12'>
                                        <div className="form-group">
                                            <label htmlFor="message">Message</label>
                                            <textarea
                                                name='message'
                                                className='form-control'
                                                required='required'
                                                value={message}
                                                id='message'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-4 col-xl-4'>
                                        <Button
                                            startIcon={<FontAwesomeIcon icon={faPaperPlane}/>}
                                            type='submit'
                                            variant="contained"
                                            color="primary">
                                            Send
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CreateWhatsappMessagePage;