import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Redirect} from "react-router";
import axios from "axios";
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import {alertService} from "../_services/alert.service";
import AsyncSelect from "react-select/async";
import {LOCAL_URL} from "../utils";

class CreateShipmentPage extends Component {
    state = {
        container_id: "",
        consignment_id: "",
        container_name: "",
        consignment_name: "",
        price_category: "",
        selectContainers: [],
        selectConsignments: [],
        selectedOptions: [],
        errorMessage: "",
        autoClose: false,
        keepAfterRouteChange: false,
        redirectToNewPage: false
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleSelectContainer(e) {
        this.setState({container_id: e.value, container_name: e.label})
    };

    handleSelectConsignment = (selectedOptions) => {
        this.setState({selectedOptions});
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        let MyResponse = '';
        const container = this.state.container_id;
        const selected = this.state.selectedOptions;
        const user = localStorage.getItem('user_id');
        if (selected.length !== 0 && container !== "") {
            const consignments = selected.map(x => x.value);
            consignments.forEach((consignment_id) => {
                axios.post(`${LOCAL_URL}cargo/api/shipments/`, {
                    container: container,
                    consignment: consignment_id,
                    user: user
                }).then(res => {
                    if (res.data.status === 1) {
                        axios.get(`${LOCAL_URL}cargo/api/get_consignment_by_id/?i=` + consignment_id).then((rsp) => {
                            let tracking_no = rsp.data[0].tracking_number;
                            let consignment_name = rsp.data[0].name;
                            let consignment_quantity = rsp.data[0].pkgs;
                            let consignment_cbm = rsp.data[0].cbm;
                            let consignment_charge = rsp.data[0].shipping_charge;
                            let customer = rsp.data[0].customer;

                            let lbl = rsp.data[0].label;
                            let consignment_label = '';

                            if (lbl === "NON" || lbl === "NONE") {
                                consignment_label = "Packages"
                            } else if (lbl !== "NON" || lbl !== "NONE") {
                                consignment_label = "Bale"
                            }

                            axios.get(`${LOCAL_URL}cargo/api/get_container/?c=` + container).then((repons) => {
                                let destination = repons.data[0].destination;
                                let arrival_date = repons.data[0].arrival_date;

                                axios.get(`${LOCAL_URL}cargo/api/get_customer_by_id/?i=` + customer).then((rsp) => {
                                    let customerName = rsp.data[0].firstname;
                                    let MyRequest = "New shipment created"
                                    MyResponse = 'Taarifa ya mzigo 📦 \n\n' +
                                        'Namba: *' + tracking_no + '*\n' +
                                        'Bidhaa: *' + consignment_name + '*\n' +
                                        'Idadi: *' + consignment_quantity + ' ' + consignment_label + '*\n' +
                                        'Cbm: *' + consignment_cbm + '* cbm\n' +
                                        'Gharama ya usafirishaji: *USD ' + consignment_charge + '*\n\n' +
                                        'Mzigo huu umesafirishwa na unategemea kuwasili bandari ya ' + destination + ' tarehe ' + arrival_date;

                                    axios.post(`https://gnmcargo.com/cargo/api/whatsapp/`, {
                                        customer_id: customer,
                                        customer_name: customerName,
                                        sender_id: rsp.data[0].cellphone,
                                        request: MyRequest,
                                        response: MyResponse,
                                        status: 0,
                                        type: "H",
                                    }).then(() => {
                                        console.log('Event Logged on DB');
                                    }).catch((error) => {
                                        console.error(error);
                                    });

                                }).catch((error) => {
                                    console.error(error);
                                });
                            }).catch((error) => {
                                console.error(error);
                            });

                        }).catch((error) => {
                            console.error(error);
                        });
                        axios.patch(`${LOCAL_URL}cargo/api/consignments/${consignment_id}/`, {
                            delivery_status: 1,
                        }).then((response) => {
                            console.log(response);
                        }).catch((error) => {
                            console.error(error);
                        });
                        axios.patch(`${LOCAL_URL}cargo/api/containers/${container}/`, {
                            shipping_status: 1,
                        }).then((response) => {
                            console.log(response);
                        }).catch((error) => {
                            console.error(error);
                        });
                        //Updating the container field in Good Consignment model
                        axios.get(`${LOCAL_URL}cargo/api/get_consigned_good/?c=` + consignment_id).then((response) => {
                            let r = response.data
                            r.forEach((d) => {
                                let ID = d.id
                                axios.patch(`${LOCAL_URL}cargo/api/good_consignments/${ID}/`, {
                                    container: container,
                                }).then((response) => {
                                    console.log(response);
                                }).catch((error) => {
                                    console.error(error);
                                });
                            });

                        });
                        this.setState({redirectToNewPage: true});
                        alertService.success('Success! Shipment successfully added', {
                            autoClose: true,
                            keepAfterRouteChange: false
                        })
                    }

                }).catch((error) => {
                    this.setState({errorMessage: error.message})
                    alertService.error('Error :( ' + error.message, {autoClose: true, keepAfterRouteChange: false})
                });

            });
        } else {
            alertService.error('Error :( Please select container and consignment' , {autoClose: true, keepAfterRouteChange: false});
            this.setState({redirectToNewPage: false});
        }

    };

     loadContainers = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        axios.get(`${LOCAL_URL}cargo/api/list_containers/?search=${inputValue}`).then(response => {
                const json = response.data.results
                callback(json.map(i => ({
                    label: i.shipping_line + " " + i.container_no + " " + i.volume + " " + i.shipping_date,
                    value: i.id
                })))
            }
        );
    }

    loadConsignments = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        axios.get(`${LOCAL_URL}cargo/api/get_waiting_consignment/?search=${inputValue}`).then(response => {
                const json = response.data.results
                callback(json.map(i => ({
                    label: i.label + " " + i.name + " " + i.cbm + " Cbm " + i.customer.firstname + " " + i.customer.lastname,
                    value: i.id
                })))
            }
        );
    }

    componentDidMount() {
        let user_id = localStorage.getItem('user_id');
        let access_token = localStorage.getItem("token")
        this.setState({id: user_id})
        this.setState({token: access_token})
    }

    render() {
        const {
            selectedOption,
        } = this.state;
        if (this.state.redirectToNewPage) {
            return (
                <Redirect to="/shipments"/>
            )
        }
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 col-xl-12">

                            <div className="header mt-md-3">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">

                                            <h1 className="header-title">
                                                <i className="fe fe-truck"/>&nbsp;&nbsp;&nbsp;
                                                Add a Shipment
                                            </h1>

                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/shipments'}>
                                                    <Button variant="contained"
                                                            startIcon={<i className="fe fe-truck"/>}>
                                                        Shipments</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <form onSubmit={this.handleSubmit} className="organizer-form">
                                <div className='row'>
                                    <div className='col-12 col-md-4 col-xl-4'>
                                        <div className="form-group">
                                            <label htmlFor="id_container">Container</label>
                                            <AsyncSelect
                                                onChange={this.handleSelectContainer.bind(this)}
                                                placeholder={'type something ...'}
                                                loadOptions={this.loadContainers}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-8 col-xl-8'>
                                        <div className="form-group">
                                            <label htmlFor="id_consignment">Consignment</label>
                                            <AsyncSelect
                                                isMulti
                                                value={selectedOption}
                                                placeholder={'type something ...'}
                                                loadOptions={this.loadConsignments}
                                                onChange={this.handleSelectConsignment.bind(this)}
                                                className="basic-multi-select"
                                                classNamePrefix="select"/>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className='primaryAction btn btn-lg btn-block btn-primary mb-3'
                                    type='submit'
                                >
                                    Create Shipment
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CreateShipmentPage;