import React, {Component} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {connect} from "react-redux";
import * as actions from "./actions/auth"
import './App.css';
import BaseRouter from "./routes";
import Header from "./common/Header";
import {history} from './_helpers/history';
import {Alert} from './_components/alert';
import StickyFooter from "./common/footer";

class App extends Component {
    componentDidMount() {
        this.props.onTryAutoSignup();
    }

    render() {
        return (
            <Router history={history}>
                <Header/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 ">
                            <Alert/>
                        </div>
                    </div>
                </div>
                <div className="container-wrapper">
                    <BaseRouter/>
                </div>
                <StickyFooter/>
            </Router>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
