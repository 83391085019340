import axios from 'axios'
import {DELETE_GOOD, GOODS_URL, LOCAL_URL, SEARCH_GOODS_URL} from '../utils/index'
import React, {Component} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import {alertService} from "../_services/alert.service";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Chip from "@material-ui/core/Chip";
import Paper from '@material-ui/core/Paper';
import {TableContainer, Tooltip} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import InfoIcon from "@material-ui/icons/Info";
import Typography from "@material-ui/core/Typography";
import {DataGrid} from '@material-ui/data-grid';
import Alert from '@material-ui/lab/Alert';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const useStyles = () => ({
    table: {
        minWidth: 1400,
    },
    container: {
        maxHeight: 800,
    },
});

const customStyle = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ListGood extends Component {
    state = {
        goods: [],
        goodList: [],
        goodDetails: [],
        previous: '',
        next: '',
        loading: true,
        loading_data: true,
        searchTerm: '',
        startDate: '',
        endDate: '',
        open: false,
        openView: false,
        deleteView: false,
        consignView: false,
        goodInfo: {
            id: "",
            name: "",
            quantity: "",
            unit: "",
            supplier_receipt_no: "",
            supplier: "",
            warehouse: "",
            customer: "",
            pckg: ""
        }
    }

    componentDidMount() {
        this.loadPosts(GOODS_URL).then()
    }

    loadPosts = async (url) => {
        await axios.get(url)
            .then((res) => {
                const goods = res.data.results
                this.setState({
                    goods,
                    previous: res.data.previous,
                    next: res.data.next,
                    loading: false
                })
            })
    }

    handleDelete = async (event) => {
        event.preventDefault();
        const good_id = this.state.goodInfo.id;
        const good_name = this.state.goodInfo.name;
        let DELETE_URL = `${DELETE_GOOD}${good_id}/`;
        axios.delete(DELETE_URL)
            .then(() => {
                alertService.warn('Success! ' + good_name + ' good successfully deleted ', {
                    autoClose: true,
                    keepAfterRouteChange: false
                })
                window.scrollTo(0, 0);
            });
        this.setState({deleteView: false})
    }


    prevPage = () => {
        this.setState({loading: true})

        if (this.state.previous !== null) {
            let http_link = this.state.previous
            this.loadPosts(http_link).then();
        } else {
            this.loadPosts(this.state.previous).then();
        }
    }

    nextPage = () => {
        this.setState({loading: true})
        if (this.state.next !== null) {
            let http_link = this.state.next
            let toPage = http_link.replace("http", "https")
            this.loadPosts(http_link).then();
        } else {
            this.loadPosts(this.state.next).then();
        }
    }

    searchPage = () => {
        let term = this.state.searchTerm
        if (term !== "") {
            this.setState({loading: true})
            let toPage = SEARCH_GOODS_URL + term;
            this.loadPosts(toPage).then();
        }
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleChangeEdit = (event) => {
        let newArr = this.state.goodInfo; // copying the old data array
        newArr[event.target.name] = event.target.value; // replace e.target.value with whatever you want to change it to
        this.setState(newArr);
    };

    ExportToExcel = async () => {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        if (startDate !== "" && endDate !== "") {
            await axios.get(`cargo/api/all_goods/?start=${startDate}&end=${endDate}`)
                .then((res) => {
                    const list = res.data;
                    const goodList = list.map(obj => {
                            return {
                                date: obj.created_date.substr(0, obj.created_date.indexOf('T')),
                                name: obj.name,
                                receipt_no: obj.supplier_receipt_no,
                                pkgs: obj.pckg,
                                unit: obj.unit,
                                quantity: obj.quantity,
                                supplier: obj.supplier.name,
                                supplier_contact: obj.supplier.cellphone,
                                customer: obj.customer.firstname,
                                warehouse: obj.warehouse.name
                            }
                        }
                    );
                    this.setState({goodList: goodList})
                })
            const fileName = "goods_list_from_" + startDate + "_to_" + endDate
            const fileType =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const fileExtension = ".xlsx";
            const ws = XLSX.utils.json_to_sheet(this.state.goodList);
            const wb = {Sheets: {data: ws}, SheetNames: ["data"]};
            const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, fileName + fileExtension);
        }
    };

    handleEdit = async (event) => {
        event.preventDefault();
        const name = this.state.goodInfo.name;
        const quantity = this.state.goodInfo.quantity;
        const unit = this.state.goodInfo.unit;
        const supplier_receipt_no = this.state.goodInfo.supplier_receipt_no;
        const warehouse = this.state.goodInfo.warehouse.id;
        const customer = this.state.goodInfo.customer.id;
        const supplier = this.state.goodInfo.supplier.id;
        const pckg = this.state.goodInfo.pckg;
        const user = localStorage.getItem('user_id');
        const id = this.state.goodInfo.id;
        // console.log(name, quantity, unit, supplier_receipt_no, supplier, warehouse, customer, pckg, user, id);
        if (name === undefined || quantity === undefined || unit === undefined || supplier_receipt_no === undefined
            || supplier === undefined || warehouse === undefined || customer === undefined) {
            alertService.error('Error :( cannot save values undefined', {autoClose: true, keepAfterRouteChange: false})
            this.setState({open: false})
        } else {
            if (customer !== "" && supplier !== "" && warehouse !== "") {
                axios.put(`${LOCAL_URL}cargo/api/goods/${id}/`, {
                    name: name,
                    quantity: quantity,
                    unit: unit,
                    supplier_receipt_no: supplier_receipt_no,
                    warehouse: warehouse,
                    customer: customer,
                    supplier: supplier,
                    pckg: pckg,
                    user: user
                }).then((res) => {
                    alertService.info('Success! ' + res.data.name + ' good edited successfully ', {
                        autoClose: true,
                        keepAfterRouteChange: false
                    })
                }).catch(error => console.error(error));
                this.setState({open: false})
            } else if (customer === "" && supplier === "" && warehouse === "") {
                axios.patch(`${LOCAL_URL}cargo/api/goods/${id}/`, {
                    name: name,
                    pckg: pckg,
                    unit: unit,
                    quantity: quantity,
                    supplier_receipt_no: supplier_receipt_no,
                    user: user
                }).then((res) => {
                    alertService.info('Success! ' + res.data.name + ' good edited successfully ', {
                        autoClose: true,
                        keepAfterRouteChange: false
                    })
                }).catch(error => console.error(error));
                this.setState({open: false})
            }

        }
    }
    _onStartFocus = (e) => {
        e.currentTarget.type = "date";
    }
    _onEndFocus = (e) => {
        e.currentTarget.type = "date";
    }
    _onStartDate = (e) => {
        e.currentTarget.type = "text";
        e.currentTarget.placeholder = "Start Date";
    }
    _onEndDate = (e) => {
        e.currentTarget.type = "text";
        e.currentTarget.placeholder = "End Date";
    }

    render() {
        const {
            openView,
            consignView,
            deleteView,
            open,
            goods,
            previous,
            next,
            loading,
            goodDetails,
            searchTerm,
            startDate,
            endDate,
            loading_data,
            goodInfo,
        } = this.state;
        const cs = customStyle;
        const renderShippingStatus = (params) => {
            if (params.value === 0) {
                return <span>
                            <Chip size="small" label="Booked"
                                  color="primary"/>
                            </span>;
            } else if (params.value === 1) {
                return <span>
                             <Chip size="small" label="Loaded"
                                   color="primary" style={{backgroundColor: "teal"}}/>
                            </span>;
            } else if (params.value === 2) {
                return <span>
                             <Chip size="small" label="Shipped"
                                   color="primary" style={{backgroundColor: "blue"}}/>
                            </span>;
            } else if (params.value === 3) {
                return <span>
                             <Chip size="small" label="Port"
                                   color="primary" style={{backgroundColor: "green"}}/>
                            </span>;
            } else if (params.value === 4) {
                return <span>
                             <Chip size="small" label="Arrived"
                                   color="primary" style={{backgroundColor: "green"}}/>
                            </span>;
            } else if (params.value === 5) {
                return <span>
                             <Chip size="small" label="Canceled"
                                   color="primary" style={{backgroundColor: "red"}}/>
                            </span>;
            }
        }
        const renderConsignedDate = (params) => {
            const created = params.value;
            return created.substr(0, created.indexOf('T'));
        }
        const
            columns = [
                {field: 'id', headerName: 'ID', width: 90, hide: true},
                {
                    field: 'c_date',
                    headerName: 'Consigned',
                    width: 200,
                    renderCell: renderConsignedDate,
                },
                {
                    field: 'consignment_name',
                    headerName: 'Item',
                    width: 200,
                },
                {
                    field: 'tracking_no',
                    headerName: 'Tracking No',
                    width: 200,
                },
                {
                    field: 'label',
                    headerName: 'Label',
                    width: 130,
                },
                {
                    field: 'pkgs',
                    headerName: 'PKGS',
                    width: 130,
                },
                {
                    field: 'quantity',
                    headerName: 'Quantity',
                    width: 130,
                },
                {
                    field: 'cbm',
                    headerName: 'CBM',
                    width: 130,
                },
                {
                    field: 'shipping',
                    headerName: 'Cost',
                    width: 130,
                },
                {
                    field: 'container_no',
                    headerName: 'Container No',
                    width: 250,
                },
                {
                    field: 'shipping_line',
                    headerName: 'Shipping Line',
                    width: 250,
                },
                {
                    field: 'shipping_date',
                    headerName: 'Shipping Date',
                    type: 'date',
                    width: 250,
                },
                {
                    field: 'arrival_date',
                    headerName: 'Arrival Date',
                    type: 'date',
                    width: 250,
                },

                {
                    field: 'shipping_status',
                    headerName: 'Status',
                    sortable: false,
                    width: 200,
                    renderCell: renderShippingStatus,
                },
            ];

        const handleClick = (good) => {
            this.setState({open: true, goodInfo: good});
        };

        const handleView = (good) => {
            this.setState({openView: true, goodInfo: good});
        };

        const handleConsigned = (good) => {
            this.setState({
                    consignView: true,
                    loading_data: true,
                    goodInfo: good
                }
            );
            const good_id = good.id;
            axios.get(`https://gnmcargo.com/cargo/api/get_shipped_good_details/?g=` + good_id).then((response) => {
                const r = response.data;
                if (r.length > 0) {
                    const good_details = r.map(d => ({
                        "id": d.id,
                        "c_date": d.consignment.created_date,
                        "consignment_name": d.consignment.name,
                        "tracking_no": d.consignment.tracking_number,
                        "label": d.consignment.label,
                        "pkgs": d.consignment.pkgs,
                        "quantity": d.quantity,
                        "cbm": d.consignment.cbm,
                        "shipping": d.consignment.shipping_charge,
                        "container_no": d.container ? d.container.container_no : '',
                        "shipping_line": d.container ? d.container.shipping_line : '',
                        "shipping_date": d.container ? d.container.shipping_date : '',
                        "arrival_date": d.container ? d.container.arrival_date : '',
                        "shipping_status": d.container ? d.container.shipping_status : '',
                    }))
                    this.setState({
                        goodDetails: good_details,
                        loading_data: false,
                    })
                } else {
                    this.setState({
                        loading_data: false,
                    })
                }
            });

        };

        const handleClose = () => {
            this.setState({
                open: false,
                openView: false,
                deleteView: false,
                consignView: false,
                goodDetails: [],
            })
        };

        const handleDeleteView = (good) => {
            this.setState({deleteView: true, goodInfo: good})
        }

        const {classes} = this.props;

        const renderDate = (good) => {
            const created = good.created_date;
            return created.substr(0, created.indexOf('T'));
        }

        const renderStatus = (good) => {
            if (good.status === 1) {
                return <span>
                     <Chip size="small" label="Consigned"
                           color="primary"/>
                </span>;
            } else if (good.status === 0) {
                return <span>
                     <Chip size="small" label="Pending"
                           color="secondary"/>
                </span>;
            } else if (good.status === 2) {
                return <span>
                     <Chip size="small" label="Stock"
                           color="warning"/>
                </span>;
            }
        }

        const renderConsigned = (good) => {
            if (good.status === 1) {
                return <span>
                     <Tooltip title="Good Details">
                        <IconButton aria-label="view"
                                    onClick={() => handleConsigned(good)}>
                            <VisibilityIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                </span>;
            } else if (good.status === 0) {
                return <span>
                </span>;
            } else if (good.status === 2) {
                return <span>
                     <Tooltip title="Good Details">
                        <IconButton aria-label="view"
                                    onClick={() => handleConsigned(good)}>
                            <VisibilityIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                </span>;
            }
        }

        return (
            <div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 ">
                            <div className="header mt-md-5">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h1 className="header-title">
                                                <i className="fe fe-codepen"/>&nbsp;&nbsp;&nbsp;
                                                All Goods
                                            </h1>
                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/goods/new'}>
                                                    <Button variant="contained" startIcon={<AddIcon/>} color='primary'>
                                                        Receive Goods</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 ">
                            {loading ? (
                                <div className='text-center'>
                                    <div className='spinner-border text-primary align-content-center' role='status'>
                                        {' '}
                                        <span className='sr-only'>Loading...</span>{' '}
                                    </div>
                                </div>
                            ) : (
                                <div className="content">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <input
                                                name="searchTerm"
                                                id="searchTerm"
                                                type="text"
                                                placeholder="Receipt No / Customer"
                                                value={searchTerm}
                                                onChange={this.handleChange}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <button onClick={this.searchPage}>Search
                                            </button>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <input
                                                type="text"
                                                onFocus={this._onStartFocus}
                                                onBlur={this._onStartDate}
                                                name="startDate"
                                                id="startDate"
                                                placeholder="Start Date"
                                                value={startDate}
                                                onChange={this.handleChange}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <input
                                                type="text"
                                                onFocus={this._onEndFocus}
                                                onBlur={this._onEndDate}
                                                name="endDate"
                                                id="endDate"
                                                value={endDate}
                                                onChange={this.handleChange}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <button onClick={this.ExportToExcel}>Export To Excel
                                            </button>
                                        </div>
                                        <div className='col-auto'>
                                            <div className="pagination">
                                                <button disabled={previous === null} onClick={this.prevPage}>Previous
                                                </button>
                                                &nbsp;&nbsp;
                                                <button disabled={next === null} onClick={this.nextPage}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <TableContainer className={classes.container} component={Paper}>
                                        <Table stickyHeader className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>Item</TableCell>
                                                    <TableCell>Receipt No</TableCell>
                                                    <TableCell>PKGS</TableCell>
                                                    <TableCell>Unit</TableCell>
                                                    <TableCell>Quantity</TableCell>
                                                    <TableCell>Supplier</TableCell>
                                                    <TableCell>Customer</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {goods.map((good) => {
                                                    return <>
                                                        <TableRow key={good.id}>
                                                            <TableCell>{renderDate(good)}</TableCell>
                                                            <TableCell>{good.name}</TableCell>
                                                            <TableCell>{good.supplier_receipt_no}</TableCell>
                                                            <TableCell>{good.pckg}</TableCell>
                                                            <TableCell>{good.unit}</TableCell>
                                                            <TableCell>{good.quantity}</TableCell>
                                                            <TableCell>{good.supplier.name}</TableCell>
                                                            <TableCell>{good.customer.firstname}</TableCell>
                                                            <TableCell>{renderStatus(good)}</TableCell>
                                                            <TableCell>
                                                                {renderConsigned(good)}
                                                                <Tooltip title="View">
                                                                    <IconButton aria-label="view"
                                                                                onClick={() => handleView(good)}>
                                                                        <InfoIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Edit">
                                                                    <IconButton aria-label="update"
                                                                                onClick={() => handleClick(good)}>
                                                                        <EditIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Delete">
                                                                    <IconButton aria-label="update"
                                                                                onClick={() => handleDeleteView(good)}>
                                                                        <DeleteIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <form onSubmit={this.handleEdit} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Edit Received Goods</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    To edit to this goods, please edit the fields below.
                                </DialogContentText>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_name">Name of Goods</label>
                                            <input
                                                type='text'
                                                name='name'
                                                placeholder='ex: Handbag'
                                                className='form-control'
                                                required='required'
                                                value={open ? goodInfo.name : ""}
                                                id='id_name'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_customer">Customer</label>
                                            <input
                                                type='text'
                                                name='name'
                                                className='form-control'
                                                required='required'
                                                disabled='true'
                                                value={open ? goodInfo.customer.firstname : ""}
                                                id='id_customer'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_supplier">Supplier</label>
                                            <input
                                                type='text'
                                                name='name'
                                                placeholder='ex: Handbag'
                                                className='form-control'
                                                required='required'
                                                disabled='true'
                                                value={open ? goodInfo.supplier.name : ""}
                                                id='id_supplier'
                                                onChange={this.handleChangeEdit}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_supplier_receipt_no">Supplier Receipt Number</label>
                                            <input
                                                type='text'
                                                name='supplier_receipt_no'
                                                placeholder='ex: 0011567'
                                                className='form-control'
                                                required=''
                                                value={open ? goodInfo.supplier_receipt_no : ""}
                                                id='id_supplier_receipt_no'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_unit">Unit</label>
                                            <select name="unit" className="required form-control" required=""
                                                    id="id_unit" value={open ? goodInfo.unit : ""}
                                                    onChange={this.handleChangeEdit}>
                                                <option value="" selected="">{open ? goodInfo.unit : ""}</option>
                                                <option value="Bale">Bale</option>
                                                <option value="Boxes">Boxes</option>
                                                <option value="Cartons">Cartons</option>
                                                <option value="Package">Package</option>
                                                <option value="Pieces">Pieces</option>
                                            </select>
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_quantity">Quantity</label>
                                            <input
                                                type='number'
                                                name='quantity'
                                                placeholder='1000'
                                                className='form-control'
                                                required='required'
                                                value={open ? goodInfo.quantity : ""}
                                                id='id_quantity'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_pckg">PKGS</label>
                                            <input
                                                type='number'
                                                name='pckg'
                                                placeholder='1000'
                                                className='form-control'
                                                required='required'
                                                value={open ? goodInfo.pckg : ""}
                                                id='id_pckg'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_warehouse">Warehouse</label>
                                            <input
                                                type='text'
                                                name='warehouse'
                                                disabled='true'
                                                className='form-control'
                                                required='required'
                                                value={open ? goodInfo.warehouse.name : ""}
                                                id='id_warehouse'
                                                onChange={this.handleChangeEdit}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    Cancel
                                </Button>
                                <Button type='submit' color="primary">
                                    Edit
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={deleteView}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <form onSubmit={this.handleDelete} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Delete Good</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete {goodInfo.name}?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    No
                                </Button>
                                <Button type='submit' color="primary">
                                    Yes
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={openView}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <DialogTitle id="form-dialog-title">Received Goods</DialogTitle>
                        <DialogContent>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Name of Goods:</strong> {openView ? goodInfo.name : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Customer:</strong> {openView ? goodInfo.customer.firstname : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Supplier:</strong> {openView ? goodInfo.supplier.name : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Supplier receipt
                                            no:</strong> {openView ? goodInfo.supplier_receipt_no : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Quantity:</strong> {openView ? goodInfo.quantity : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Unit:</strong> {openView ? goodInfo.unit : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Warehouse:</strong> {openView ? goodInfo.warehouse.name : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Created
                                            By:</strong> {openView ? goodInfo.user.first_name + " " + goodInfo.user.last_name : ""}
                                    </Typography>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        fullScreen
                        open={consignView}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                        aria-labelledby="form-dialog-title">
                        <AppBar className={cs.appBar}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                    <CloseIcon/>
                                </IconButton>
                                <Typography variant="h6" className={cs.title}>
                                    {consignView ? goodInfo.customer.firstname+ " "+ goodInfo.name + " "
                                        + goodInfo.supplier_receipt_no : ""}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <div className='pd-top'>
                                <div className="pb-2">
                                    <Alert severity="info">
                                        {consignView ? goodInfo.name : ""} has been consigned below are details!
                                    </Alert>
                                </div>
                                {loading_data ? (
                                    <div className='text-center'>
                                        <div className='spinner-border text-primary align-content-center'
                                             role='status'>
                                            {' '}
                                            <span className='sr-only'>Loading...</span>{' '}
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div style={{height: 400, width: '100%'}}>
                                            <DataGrid
                                                getRowId={(r) => r.id}
                                                rows={goodDetails}
                                                columns={columns}
                                                pageSize={5}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>

        )
    }
}

export default withStyles(useStyles)(ListGood);
