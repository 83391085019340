import React from "react";
import Select from "react-select";

const ConsignmentList = props => {
    return props.consignmentDetails.map((val, idx) => {
        let item = `good-${idx}`,
            unit = `unit-${idx}`,
            quantity = `quantity-${idx}`,
            pkgs = `pkgs-${idx}`;
        return (
            <div className="row" key={val.index}>
                <div className="col p-4">
                    {idx === 0 ? (
                        <button
                            onClick={() => props.add()}
                            type="button"
                            className="btn btn-primary text-center"
                        >
                            <i className="fe fe-plus-circle" aria-hidden="true"/>
                        </button>
                    ) : (
                        <button
                            className="btn btn-danger"
                            onClick={() => props.delete(val)}
                        >
                            <i className="fe fe-minus-circle" aria-hidden="true"/>
                        </button>
                    )}
                </div>
                <div className='col-12 col-md-4 col-xl-4'>
                    <div className="form-group">
                        <label>Goods</label>
                        <Select
                            options={props.goodOptions}
                            onChange={props.goodChange}
                            name="item"
                            id={item}
                            data-id={idx}
                            value={props.consignmentDetails[idx].item}
                        />
                    </div>
                </div>
                <div className='col-12 col-md-3 col-xl-3'>
                    <div className="form-group">
                        <label>Unit</label>
                        <select className="form-control" name="unit" id={unit} data-id={idx}
                                value={props.consignmentDetails[idx].unit} required='required' defaultValue="pieces"
                                onChange={props.onChange}
                        >
                            <option value="Pieces">Pieces</option>
                            <option value="Prs">Prs</option>
                            <option value="Bale">Bale</option>
                            <option value="Boxes">Boxes</option>
                            <option value="Cartons">Cartons</option>
                            <option value="Dozen">Dozen</option>
                            <option value="Package">Package</option>
                            <option value="Set">Set</option>
                        </select>
                    </div>
                </div>
                <div className='col-12 col-md-2 col-xl-2'>
                    <div className="form-group">
                        <label>Quantity</label>
                        <input
                            type="number"
                            className="form-control"
                            placeholder="1000"
                            required='required'
                            name="quantity"
                            id={quantity}
                            data-id={idx}
                            onChange={props.onChange}
                            value={props.consignmentDetails[idx].quantity}
                        />
                    </div>
                </div>
                <div className='col-12 col-md-2 col-xl-2'>
                    <div className="form-group">
                        <label>Pkgs</label>
                        <input
                            type="number"
                            className="form-control"
                            placeholder="1000"
                            required='required'
                            name="pkgs"
                            id={pkgs}
                            data-id={idx}
                            onChange={props.onChange}
                            value={props.consignmentDetails[idx].pkgs}
                        />
                    </div>
                </div>
            </div>
        );
    });
};
export default ConsignmentList;
