import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import { logout } from "../actions/auth";
import { connect } from "react-redux";
import {Redirect} from "react-router";

class Header extends React.Component {
  componentDidMount() {
  }
  state = {
        redirectToLogout: false
    };

  handleLogout = async (event) => {
    event.preventDefault();
    logout();
  };

  render() {

    return (
        <nav className="navbar navbar-expand-lg navbar-light" id="topnav">
        <div className="container">
          <button className="navbar-toggler mr-auto" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"/>
          </button>
          <div className="navbar-user">
            <div className="avatar-img rounded-circle">
              <Link to="/login">
              <i className="h2 fe fe-user">                      </i>
              </Link>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div className="avatar-img rounded-circle">
              <Link  to={'/login'} onClick={this.handleLogout}>
              <i className="h2 fe fe-log-out"/>
              </Link>
            </div>
          </div>
          <div className="collapse navbar-collapse mr-auto order-lg-first" id="navbar">
            <ul className="navbar-nav mr-auto">
              <h2 className="text-muted nav-item mb-0">
                <div>
                  <Link to='/'>
                  CMTS
                  </Link>
                </div>
              </h2>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}


const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
  };
}

export default withRouter(
  connect(
    mapDispatchToProps
  )(Header)
);