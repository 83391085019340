import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Redirect} from "react-router";
import axios from "axios";
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import {alertService} from "../_services/alert.service";
import ConsignmentList from "./consignmentList";
import AsyncSelect from "react-select/async";
import {LOCAL_URL} from "../utils";

class CreateConsignmentPage extends Component {
    state = {
        warehouse_id: "",
        customer_id: "",
        price_id: "",
        good_id: "",
        good_name: "",
        price_name: "",
        customer_name: "",
        warehouse_name: "",
        c_name: "",
        shipping_charge: "",
        cbm: "",
        label: "",
        supplier: "",
        warehouse: "",
        customer: "",
        good: "",
        pkgs: "",
        price_category: "",
        selectGoods: [],
        consignmentDetails: [
            {
                index: Math.random(),
                good: "",
                unit: "",
                quantity: "",
                pkgs: "",
            }
        ],
        redirectToNewPage: false,
    };

    async getGood(customer) {
        axios.get(`${LOCAL_URL}cargo/api/get_waiting_customer_goods/?s=0&c=${customer}`)
            .then(r => {
                const goodsList = r.data;
                const goods = goodsList.map(d => ({
                    "value": d.id,
                    "label": d.customer.firstname + " " + d.supplier_receipt_no + " " + d.name + " " + d.quantity + " " + d.unit + " " + d.pckg + " " + "pkgs"
                }));
                this.setState({selectGoods: goods})
            })
    }

    handleChange = (event) => {
        console.log(event.target.name, event.target.value)
        this.setState({[event.target.name]: event.target.value});
    };

    handleSelectCustomer = (e) => {
        this.setState({customer_id: e.value, customer_name: e.label})
        this.getGood(e.value).then()
    };

    handleChangeGood = (e) => {
        this.setState({good_id: e.value, good_name: e.label})
    };

    handleChangeConsignmentList = (e) => {
        console.log('Called: ', e)
        if (
            ["good", "unit", "quantity", "pkgs"
            ].includes(
                e.target.name
            )
        ) {
            let consignmentDetails = [...this.state.consignmentDetails];
            consignmentDetails[e.target.dataset.id][e.target.name] = e.target.value;
            consignmentDetails[e.target.dataset.id]["good"] = this.state.good_id;
            if (consignmentDetails[e.target.dataset.id]["unit"] === "") {
                consignmentDetails[e.target.dataset.id]["unit"] = "Pieces";
            }
            this.setState({consignmentDetails});
        } else {
            this.setState({[e.target.name]: e.target.value});
        }
    };

    addNewRow = () => {
        this.setState(prevState => ({
            consignmentDetails: [
                ...prevState.consignmentDetails,
                {
                    index: Math.random(),
                    good: "",
                    unit: "",
                    quantity: "",
                    pkgs: "",
                }
            ]
        }));
    };

    deleteRow = index => {
        this.setState({
            consignmentDetails: this.state.consignmentDetails.filter(
                (s, sindex) => index !== sindex
            )
        });
    };

    clickOnDelete(record) {
        this.setState({
            consignmentDetails: this.state.consignmentDetails.filter(r => r !== record)
        });
    }

    handleSelectPrice = (e) => {
        this.setState({price_id: e.value, price_name: e.label})
    };

    loadOptions = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        axios.get(`${LOCAL_URL}cargo/api/list_customers/?search=${inputValue}`).then(response => {
                const json = response.data.results
                callback(json.map(i => ({
                    label: i.cellphone + " " + i.firstname + " " + i.lastname,
                    value: i.id
                })))
            }
        );
    }

    loadCategory = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        axios.get(`${LOCAL_URL}cargo/api/list_categories/?search=${inputValue}`).then(response => {
                const json = response.data.results
                callback(json.map(i => ({
                    label: i.name,
                    value: i.id
                })))
            }
        );
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        // let MyResponse = '';
        let new_warehouse = 0;
        const c_name = this.state.c_name;
        const shipping_charge = this.state.shipping_charge;
        const cbm = this.state.cbm;
        const pkgs = this.state.pkgs;
        const warehouse = this.state.warehouse_id;
        if (warehouse === "") {
            new_warehouse = "2";
        } else if (warehouse !== "") {
            new_warehouse = warehouse
        }
        const customer = this.state.customer_id;
        const price_category = this.state.price_id;
        const consignmentDetails = this.state.consignmentDetails;
        const label = this.state.label;
        const user = localStorage.getItem('user_id');

        let output_good_id = [], l = consignmentDetails.length, i;
        for (i = 0; i < l; i++) {
            output_good_id.push(consignmentDetails[i].good);
        }
        const good = output_good_id.toString();
        // console.log(c_name, shipping_charge, quantity, cbm, pkgs, warehouse, customer, price_category, good, user);

        axios.post(`${LOCAL_URL}cargo/api/consignments/`, {
            name: c_name,
            shipping_charge: shipping_charge,
            cbm: cbm,
            good: good,
            warehouse: new_warehouse,
            customer: customer,
            price_category: price_category,
            pkgs: pkgs,
            label: label,
            user: user
        }).then(res => {
            if (res.data.delivery_status === 0) {
                // let tracking_no = res.data.tracking_number;
                let consignment_id = res.data.id;
                // let consignment_name = res.data.name;
                // let consignment_quantity = res.data.pkgs;
                // let consignment_cbm = res.data.cbm;
                // let consignment_charge = res.data.shipping_charge;
                // let lbl = res.data.label;
                // let consignment_label = '';
                // if (lbl === "NON" || lbl === "NONE") {
                //     consignment_label = "Packages"
                // } else if (lbl !== "NON" || lbl !== "NONE") {
                //     consignment_label = "Bale"
                // }
                // axios.get('/cargo/api/get_customer_by_id/?i=' + customer).then((rsp) => {
                //     let customerName = rsp.data[0].firstname;
                //     let MyRequest = "New consignment created"
                //     MyResponse = 'Taarifa ya mzigo 📦 \n\n' +
                //         'Namba: *' + tracking_no + '*\n' +
                //         'Bidhaa: *' + consignment_name + '*\n' +
                //         'Idadi: *' + consignment_quantity + ' '+ consignment_label + '*\n' +
                //         'Cbm: *' + consignment_cbm + '* cbm\n' +
                //         'Gharama ya usafirishaji: *USD ' + consignment_charge + '*\n\n' +
                //         'Mzigo wako umepokelewa leo na utapewa taarifa utakapo safirishwa.';
                //     axios.post('/cargo/api/whatsapp/', {
                //         customer_id: customer,
                //         customer_name: customerName,
                //         sender_id: rsp.data[0].cellphone,
                //         request: MyRequest,
                //         response: MyResponse,
                //         status: 0,
                //         type: "G",
                //     }).then((response) => {
                //         console.log(response + 'Event Logged on DB');
                //     }).catch((error) => {
                //         console.error(error);
                //     });
                // }).catch((error) => {
                //     console.error(error);
                // });

                // Create Link with Goods and Consignment && Change status of goods
                consignmentDetails.forEach((item) => {
                    axios.post(`${LOCAL_URL}cargo/api/good_consignments/`, {
                        good: item.good,
                        consignment: consignment_id,
                        container: null,
                        unit: item.unit,
                        customer: customer,
                        pkgs: item.pkgs,
                        quantity: item.quantity,
                        user: user,
                        status: 1
                    }).then().catch((error) => {
                        console.error(error);
                    });
                    axios.get(`${LOCAL_URL}cargo/api/goods/${item.good}/`).then((response) => {
                        const quantity = response.data.quantity
                        const previous_pkgs = response.data.pckg
                        if (item.quantity > quantity) {
                            alertService.error('Error :( Packed pieces is grater than remaining pieces', {
                                autoClose: false,
                                keepAfterRouteChange: false
                            })
                        } else if (item.quantity <= quantity) {
                            const remaining_pieces = quantity - item.quantity;
                            if (remaining_pieces > 0) {
                                axios.patch(`${LOCAL_URL}cargo/api/goods/${item.good}/`, {
                                    quantity: remaining_pieces,
                                    status: 2
                                }).then((response) => {
                                    alertService.info('Success! ' + response.data.name + ' quantity reduced to ' + remaining_pieces, {
                                        autoClose: true,
                                        keepAfterRouteChange: false
                                    })
                                });
                            } else if (remaining_pieces === 0) {
                                axios.patch(`${LOCAL_URL}cargo/api/goods/${item.good}/`, {
                                    quantity: remaining_pieces,
                                    status: 1
                                }).then((response) => {
                                    alertService.info('Success! ' + response.data.name + ' quantity reduced to ' + remaining_pieces, {
                                        autoClose: true,
                                        keepAfterRouteChange: false
                                    })
                                });
                            } else if (remaining_pieces < 0) {
                                alertService.error('Error :( Packed pieces is grater than remaining pieces', {
                                    autoClose: true,
                                    keepAfterRouteChange: false
                                })
                            }
                        }
                        // *********************************************** PACKAGES *******************************************************
                        if (item.pkgs > previous_pkgs) {
                            alertService.error('Error :( Packed packages is grater than remaining packages', {
                                autoClose: true,
                                keepAfterRouteChange: false
                            })
                        } else if (item.pkgs <= previous_pkgs) {
                            const remaining_pkgs = previous_pkgs - item.pkgs;
                            if (remaining_pkgs > 0) {
                                axios.patch(`${LOCAL_URL}cargo/api/goods/${item.good}/`, {
                                    pckg: remaining_pkgs,
                                    status: 2
                                }).then((response) => {
                                    alertService.info('Success! ' + response.data.name + ' packages reduced to ' + remaining_pkgs, {
                                        autoClose: true,
                                        keepAfterRouteChange: false
                                    })
                                });
                            } else if (remaining_pkgs === 0) {
                                axios.patch(`${LOCAL_URL}cargo/api/goods/${item.good}/`, {
                                    pckg: remaining_pkgs,
                                    status: 1
                                }).then((response) => {
                                    alertService.info('Success! ' + response.data.name + ' packages reduced to ' + remaining_pkgs, {
                                        autoClose: true,
                                        keepAfterRouteChange: false
                                    })
                                });
                            } else if (remaining_pkgs < 0) {
                                alertService.error('Error :( Packed packages is grater than remaining packages', {
                                    autoClose: true,
                                    keepAfterRouteChange: false
                                })
                            }
                        }
                        // ************************************************ PACKAGES *******************************************************
                    });

                });
                this.setState({redirectToNewPage: true});
                alertService.success('Success! Consignment successfully added', {
                    autoClose: true,
                    keepAfterRouteChange: false
                })
            }

        }).catch((error) => {
            this.setState({errorMessage: error.message})
            alertService.error('Error :( ' + error.message, {autoClose: true, keepAfterRouteChange: false})
        });

    };

    componentDidMount() {
        let user_id = localStorage.getItem('user_id');
        let access_token = localStorage.getItem("token")
        this.setState({id: user_id})
        this.setState({token: access_token})
    }

    render() {
        const {
            c_name,
            warehouse,
            shipping_charge,
            cbm,
            pkgs,
            label,
            consignmentDetails,
        } = this.state;
        if (this.state.redirectToNewPage) {
            return (
                <Redirect to="/consignments"/>
            )
        }
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12">

                            <div className="header mt-md-3">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">

                                            <h1 className="header-title">
                                                <i className="fe fe-package"/>&nbsp;&nbsp;&nbsp;
                                                Add a Consignment
                                            </h1>

                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>}
                                                            color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/consignments'}>
                                                    <Button variant="contained"
                                                            startIcon={<i className="fe fe-package"/>}>
                                                        Consignments</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={this.handleSubmit}
                                  className="organizer-form">
                                <div className='row'>
                                    <div className='col-12 col-md-4 col-xl-4'>
                                        <div className="form-group">
                                            <label htmlFor="id_customer">Customer</label>
                                            <AsyncSelect
                                                onChange={this.handleSelectCustomer.bind(this)}
                                                placeholder={'type something ...'}
                                                loadOptions={this.loadOptions}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 col-xl-3'>
                                        <div className="form-group">
                                            <label htmlFor="id_name">Name of Consignment</label>
                                            <input
                                                type='text'
                                                name='c_name'
                                                placeholder='ex: Jeans'
                                                className='form-control'
                                                required='required'
                                                value={c_name}
                                                id='id_c_name'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 col-xl-3'>
                                        <div className="form-group">
                                            <label htmlFor="id_warehouse">Warehouse</label>
                                            <select name="warehouse" className="required form-control"
                                                    required="required"
                                                    id="id_warehouse" value={warehouse} onChange={this.handleChange}>
                                                <option value="2">WAREHOUSE CHINA</option>
                                                <option value="3">WAREHOUSE DUBAI</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-2 col-xl-2'>
                                        <div className="form-group">
                                            <label htmlFor="id_price">Price Category</label>
                                            <AsyncSelect
                                                onChange={this.handleSelectPrice.bind(this)}
                                                placeholder={'type something ...'}
                                                loadOptions={this.loadCategory}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-4 col-xl-4'>

                                        <div className="form-group">
                                            <label htmlFor="id_label">Label</label>
                                            <input
                                                type='text'
                                                name='label'
                                                placeholder='ex: Bale/ Box/ CTN'
                                                className='form-control'
                                                required='required'
                                                value={label}
                                                id='id_label'
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 col-xl-3'>
                                        <div className="form-group">
                                            <label htmlFor="id_pkgs">PKGS</label>
                                            <input
                                                type='number'
                                                name='pkgs'
                                                placeholder='ex: 1'
                                                className='form-control'
                                                required='required'
                                                value={pkgs}
                                                id='id_pkgs'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 col-xl-3'>
                                        <div className="form-group">
                                            <label htmlFor="id_cbm">CBM</label>
                                            <input
                                                type='number'
                                                name='cbm'
                                                placeholder='ex: 10'
                                                className='form-control'
                                                required='required'
                                                value={cbm}
                                                id='id_cbm'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-2 col-xl-2'>
                                        <div className="form-group">
                                            <label htmlFor="id_shipping_charge">Shipping Cost</label>
                                            <input
                                                type='number'
                                                name='shipping_charge'
                                                placeholder='100'
                                                className='form-control'
                                                value={shipping_charge}
                                                id='id_shipping_charge'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ConsignmentList
                                    add={this.addNewRow}
                                    delete={this.clickOnDelete.bind(this)}
                                    goodOptions={this.state.selectGoods}
                                    goodChange={this.handleChangeGood.bind(this)}
                                    consignmentDetails={consignmentDetails}
                                    onChange={this.handleChangeConsignmentList}
                                />
                                <button
                                    className='primaryAction btn btn-lg btn-block btn-primary mb-3'
                                    type='submit'
                                >
                                    Create Consignment
                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CreateConsignmentPage;
