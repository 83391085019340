import React, {Component} from 'react';
import '../fonts/feather/feather.min.css';
import {Link, Redirect} from 'react-router-dom'
import {connect} from "react-redux";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import {faSms, faAddressBook} from '@fortawesome/free-solid-svg-icons';
import {LOCAL_URL} from "../utils";

class Homepage extends Component {
    constructor(props) {
        super(props);
        // console.log("Constructing App");
        this.state = {
            name: '',
            token: '',
            shipmentList: [],
            consignmentList: [],
            customerList: []
        }
    }

    componentDidMount() {
        let fname = localStorage.getItem('full_name');
        let access_token = localStorage.getItem("token")
        this.setState({name: fname})
        this.setState({token: access_token})
        axios.get(`${LOCAL_URL}cargo/api/list_shipments/`)
            .then(r => {
                const shipmentList = r.data.count;
                this.setState({shipmentList: shipmentList});
            })
        axios.get(`${LOCAL_URL}cargo/api/list_customers/`)
            .then(r => {
                const customerList = r.data.count;
                this.setState({customerList: customerList});
            })
        axios.get(`${LOCAL_URL}cargo/api/list_consignments/`)
            .then(r => {
                const consignmentList = r.data.count;
                this.setState({consignmentList: consignmentList});
            })
    }

    render() {
        const {token} = this.props;
        const {
            customerList,
            consignmentList,
            shipmentList
        } = this.state;
        if (token) {
            return (
                <React.Fragment>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-12 col-lg-10 col-xl-8'>
                                <div className='header mt-md-2'>
                                    <div className='header-body'>
                                        <h1 className='header-title'>First Vision Cargo</h1>
                                        <p className='header-subtitle'>Welcome - {this.state.name}</p>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12 col-lg-6 col-xl'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <div className='row align-items-center'>
                                                    <div className='col'>
                                                        <h6 className='card-title text-uppercase text-muted mb-2'>
                                                            Total Shipments
                                                        </h6>

                                                        <span className='h2 mb-0'>{shipmentList}</span>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <span className='h2 fe fe-truck text-muted mb-0'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12 col-lg-6 col-xl'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <div className='row align-items-center'>
                                                    <div className='col'>
                                                        <h6 className='card-title text-uppercase text-muted mb-2'>
                                                            Total Consignments
                                                        </h6>

                                                        <span className='h2 mb-0'>{consignmentList}</span>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <span className='h2 fe fe-tag text-muted mb-0'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-6 col-xl'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <div className='row align-items-center'>
                                                    <div className='col'>
                                                        <h6 className='card-title text-uppercase text-muted mb-2'>
                                                            Customers
                                                        </h6>

                                                        <div className='row align-items-center no-gutters'>
                                                            <div className='col-auto'>
                                                                <span className='h2 mr-2 mb-0'>{customerList}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <span className='h2 fe fe-user text-muted mb-0'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-4'>
                                        <div className='card'>
                                            <div className='card-header'>
                                                <h3 className='card-header-title'>
                                                    <i className='fe fe-codepen'/> &nbsp;&nbsp;&nbsp; Received Goods
                                                </h3>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/goods/new">
                                                        <i className='fe fe-plus-circle'/> &nbsp;&nbsp;&nbsp;
                                                        Add New
                                                    </Link>
                                                </h4>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/goods">
                                                        <i className='fe fe-list'/> &nbsp;&nbsp;&nbsp; All
                                                        Received Goods
                                                    </Link>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='col-12 col-md-6 col-xl-4'>
                                        <div className='card'>
                                            <div className='card-header'>
                                                <h3 className='card-header-title'>
                                                    <i className='fe fe-package'/> &nbsp;&nbsp;&nbsp; Consignments
                                                </h3>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/consignments/new">
                                                        <i className='fe fe-plus-circle'/> &nbsp;&nbsp;&nbsp;
                                                        Add New
                                                    </Link>
                                                </h4>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/consignments">
                                                        <i className='fe fe-list'/> &nbsp;&nbsp;&nbsp; All Consignments
                                                    </Link>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-4'>
                                        <div className='card'>
                                            <div className='card-header'>
                                                <h3 className='card-header-title'>
                                                    <i className='fe fe-users'/> &nbsp;&nbsp;&nbsp; Customers
                                                </h3>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/customers/new">
                                                        <i className='fe fe-plus-circle'/> &nbsp;&nbsp;&nbsp;
                                                        Add New
                                                    </Link>
                                                </h4>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/customers">
                                                        <i className='fe fe-list'/> &nbsp;&nbsp;&nbsp; All Customers
                                                    </Link>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>

                                    <div className='col-12 col-md-6 col-xl-4'>
                                        <div className='card'>
                                            <div className='card-header'>
                                                <h3 className='card-header-title'>
                                                    <i className='fe fe-box'/> &nbsp;&nbsp;&nbsp; Containers
                                                </h3>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/containers/new">
                                                        <i className='fe fe-plus-circle'/> &nbsp;&nbsp;&nbsp;
                                                        Add New
                                                    </Link>
                                                </h4>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/containers">
                                                        <i className='fe fe-list'/> &nbsp;&nbsp;&nbsp; All Containers
                                                    </Link>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-12 col-md-6 col-xl-4'>
                                        <div className='card'>
                                            <div className='card-header'>
                                                <h3 className='card-header-title'>
                                                    <i className='fe fe-truck'/> &nbsp;&nbsp; Shipments
                                                </h3>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/shipments/new">
                                                        <i className='fe fe-plus-circle'/> &nbsp;&nbsp;&nbsp;
                                                        Add New
                                                    </Link>
                                                </h4>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to='/shipments'>
                                                        <i className='fe fe-list'/> &nbsp;&nbsp;&nbsp; All Shipments
                                                    </Link>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-12 col-md-6 col-xl-4'>
                                        <div className='card'>
                                            <div className='card-header'>
                                                <h3 className='card-header-title'>
                                                    <i className='fe fe-users'/> &nbsp;&nbsp; Suppliers
                                                </h3>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/suppliers/new">
                                                        <i className='fe fe-plus-circle'/> &nbsp;&nbsp;&nbsp;
                                                        Add New
                                                    </Link>
                                                </h4>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to='/suppliers'>
                                                        <i className='fe fe-list'/> &nbsp;&nbsp;&nbsp; All Suppliers
                                                    </Link>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-4'>
                                        <div className='card'>
                                            <div className='card-header'>
                                                <h3 className='card-header-title'>
                                                    <i className='fe fe-clipboard'/> &nbsp;&nbsp;&nbsp; Price Category
                                                </h3>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/categories/new">
                                                        <i className='fe fe-plus-circle'/> &nbsp;&nbsp;&nbsp;
                                                        Add New
                                                    </Link>
                                                </h4>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/categories">
                                                        <i className='fe fe-list'/> &nbsp;&nbsp;&nbsp; All Price
                                                        Categories
                                                    </Link>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-12 col-md-6 col-xl-4'>
                                        <div className='card'>
                                            <div className='card-header'>
                                                <h3 className='card-header-title'>
                                                    <i className='fe fe-user'/> &nbsp;&nbsp;&nbsp; Users
                                                </h3>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/users/new-user">
                                                        <i className='fe fe-plus-circle'/> &nbsp;&nbsp;&nbsp;
                                                        Add New User
                                                    </Link>
                                                </h4>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/users">
                                                        <i className='fe fe-list'/> &nbsp;&nbsp;&nbsp; All Users
                                                    </Link>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-12 col-md-6 col-xl-4'>
                                        <div className='card'>
                                            <div className='card-header'>
                                                <h3 className='card-header-title'>
                                                    <i className='fe fe-home'/> &nbsp;&nbsp; Warehouses
                                                </h3>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/warehouses/new">
                                                        <i className='fe fe-plus-circle'/> &nbsp;&nbsp;&nbsp;
                                                        Add New
                                                    </Link>
                                                </h4>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to='/warehouses'>
                                                        <i className='fe fe-list'/> &nbsp;&nbsp;&nbsp; All Warehouses
                                                    </Link>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-4'>
                                        <div className='card'>
                                            <div className='card-header'>
                                                <h3 className='card-header-title'>
                                                    <FontAwesomeIcon icon={faWhatsapp} /> &nbsp;&nbsp;&nbsp; Whatsapp
                                                </h3>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/whatsapp/new">
                                                        <i className='fe fe-plus-circle'/> &nbsp;&nbsp;&nbsp;
                                                        Add New
                                                    </Link>
                                                </h4>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/whatsapp">
                                                        <i className='fe fe-list'/> &nbsp;&nbsp;&nbsp; All Messages
                                                    </Link>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-4'>
                                        <div className='card'>
                                            <div className='card-header'>
                                                <h3 className='card-header-title'>
                                                    <FontAwesomeIcon icon={faAddressBook} /> &nbsp;&nbsp;&nbsp; Invoice
                                                </h3>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="">
                                                        <i className='fe fe-plus-circle'/> &nbsp;&nbsp;&nbsp;
                                                        Add New
                                                    </Link>
                                                </h4>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="">
                                                        <i className='fe fe-list'/> &nbsp;&nbsp;&nbsp; All Invoice
                                                    </Link>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-4'>
                                        <div className='card'>
                                            <div className='card-header'>
                                                <h3 className='card-header-title'>
                                                    <FontAwesomeIcon icon={faSms} /> &nbsp;&nbsp;&nbsp; SMS
                                                </h3>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/sms/new">
                                                        <i className='fe fe-plus-circle'/> &nbsp;&nbsp;&nbsp;
                                                        Add New
                                                    </Link>
                                                </h4>
                                            </div>

                                            <div className='card-header'>
                                                <h4 className='card-header-title'>
                                                    <Link to="/sms">
                                                        <i className='fe fe-list'/> &nbsp;&nbsp;&nbsp; All SMS
                                                    </Link>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else {
            return <Redirect to="/login"/>;
        }

    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token
    };
};

export default connect(
    mapStateToProps,
)(Homepage);
