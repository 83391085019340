import axios from 'axios'
import {SMS_URL, SEARCH_SMS_URL, DELETE_SMS, username, password, LOCAL_URL} from '../utils/index'
import React, {Component} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import {alertService} from "../_services/alert.service";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import AsyncSelect from "react-select/async";
import Chip from "@material-ui/core/Chip";
import Paper from '@material-ui/core/Paper';
import {TableContainer, Tooltip} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import InfoIcon from "@material-ui/icons/Info";
import Typography from "@material-ui/core/Typography";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane, faSms} from '@fortawesome/free-solid-svg-icons';
import {encode as btoa} from 'base-64';

const useStyles = () => ({
    table: {
        minWidth: 1250,
    },
    container: {
        maxHeight: 800,
    },
});

class ListSms extends Component {
    state = {
        smsz: [],
        balance: [],
        previous: '',
        next: '',
        loading: true,
        searchTerm: '',
        container_id: '',
        container_no: '',
        open: false,
        openView: false,
        sendView: false,
        deleteView: false,
        smsInfo: {
            id: "",
            customer_id: "",
            sender_id: "",
            response: "",
            status: "",
        }
    }

    componentDidMount() {
        this.loadPosts(SMS_URL).then()
        this.loadSmsBalance()
    }

    loadPosts = async (url) => {
        await axios.get(url)
            .then((res) => {
                const smsz = res.data.results
                this.setState({
                    smsz,
                    previous: res.data.previous,
                    next: res.data.next,
                    loading: false
                })
            })
    }

    loadSmsBalance = () => {
        const content_type = "application/json";
        axios.get('https://apisms.beem.africa/public/v1/vendors/balance', {
                headers: {
                    "Content-Type": content_type,
                    Authorization: "Basic " + btoa(username + ":" + password),
                }
            }
        ).then(r => {
            const balance = r.data.data.credit_balance;
            this.setState({balance: balance});
        })
    }

    loadContainer = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        axios.get(`${LOCAL_URL}cargo/api/list_containers/?search=${inputValue}`).then(response => {
                const json = response.data.results
                callback(json.map(i => ({
                    label: i.container_no + " " + i.destination + " " + i.arrival_date,
                    value: i.id
                })))
            }
        );
    }

    handleSelectContainer = (e) => {
        this.setState({container_id: e.value, container_no: e.label})
    };


    handleDelete = async (event) => {
        event.preventDefault();
        const sms_id = this.state.smsInfo.id;
        let DELETE_URL = DELETE_SMS + sms_id;
        axios.delete(DELETE_URL)
            .then(() => {
                alertService.warn('Success! sms successfully deleted ', {
                    autoClose: true,
                    keepAfterRouteChange: false
                })
                window.scrollTo(0, 0);
            });
        this.setState({deleteView: false})
    }

    handleSendSms = async (event) => {
        event.preventDefault();

        const container_id = this.state.container_id
        const removeDuplicates = (duplicates) => {
            const flag = {};
            const unique = [];
            duplicates.forEach(elem => {
                if (!flag[elem.customer.id]) {
                    flag[elem.customer.id] = true;
                    unique.push(elem);
                }
            });
            return unique;
        }
        axios.get(`${LOCAL_URL}cargo/api/get_shipped_container/?c=` + container_id).then((response) => {
            const r = response.data;
            if (r.length > 0) {
                const unique_customers = removeDuplicates(r);
                const customers = unique_customers.map(d => ({
                    "customer_id": d.customer.id,
                    "customer_name": d.customer.firstname,
                    "sender_id": d.customer.cellphone,
                }))
                customers.forEach((customer) => {
                    let MyResponse = 'Habari, Mzigo wako umewasili kwenye godown letu. ' +
                        'Tafadhali fika ofisini kwetu. Asante kwa kuichagua GNMCARGO.';
                    if (customer.sender_id.startsWith("255") && customer.sender_id.length === 12) {
                        axios.post('https://gnmcargo.com/cargo/api/sms/', {
                            customer_id: customer.customer_id,
                            sender_id: customer.sender_id,
                            response: MyResponse,
                            status: 1,
                            type: "Container Arrival",
                        }).then(
                            () => {
                                alertService.success('Success! sms successfully sent', {
                                    autoClose: true,
                                    keepAfterRouteChange: false
                                })
                                window.scrollTo(0, 0);
                            }
                        ).catch((error) => {
                            console.error(error);
                        });
                        const content_type = "application/json";
                        axios.post('https://apisms.beem.africa/v1/send',
                            {
                                source_addr: "INFO",
                                schedule_time: "",
                                encoding: 0,
                                message: MyResponse,
                                recipients: [
                                    {
                                        recipient_id: 1,
                                        dest_addr: customer.sender_id,
                                    },
                                ],
                            },
                            {
                                headers: {
                                    "Content-Type": content_type,
                                    Authorization: "Basic " + btoa(username + ":" + password),
                                }
                            }
                        ).then(() => {
                            alertService.info('Success! sms successfully pushed to Beem', {
                                autoClose: true,
                                keepAfterRouteChange: false
                            })
                        })
                    } else {
                        axios.post(`${LOCAL_URL}cargo/api/sms/`, {
                            customer_id: customer.customer_id,
                            sender_id: customer.sender_id,
                            response: MyResponse,
                            status: 0,
                            type: "Container Arrival",
                        }).then(
                            () => {
                                alertService.warn('Sorry! Failed to send sms in correct phone number '
                                    + customer.sender_id, {
                                    autoClose: false,
                                    keepAfterRouteChange: false
                                })
                                window.scrollTo(0, 0);
                            }
                        ).catch((error) => {
                            console.error(error);
                        });
                    }
                })
            } else {
                alertService.warn('Sorry! This container is empty.', {
                    autoClose: true,
                    keepAfterRouteChange: false
                })
            }
        });
        this.setState({sendView: false})
    }


    prevPage = () => {
        this.setState({loading: true})

        if (this.state.previous !== null) {
            let http_link = this.state.previous
            let toPage = http_link.replace("http", "https")
            this.loadPosts(toPage).then();
        } else {
            this.loadPosts(this.state.previous).then();
        }
    }

    nextPage = () => {
        this.setState({loading: true})
        if (this.state.next !== null) {
            let http_link = this.state.next
            let toPage = http_link.replace("http", "https")
            this.loadPosts(toPage).then();
        } else {
            this.loadPosts(this.state.next).then();
        }
    }

    searchPage = () => {
        let term = this.state.searchTerm
        if (term !== "") {
            this.setState({loading: true})
            let toPage = SEARCH_SMS_URL + term;
            this.loadPosts(toPage).then();
        }
    }
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleChangeEdit = (event) => {
        let newArr = this.state.smsInfo; // copying the old data array
        newArr[event.target.name] = event.target.value; // replace e.target.value with whatever you want to change it to
        this.setState(newArr);
    };


    handleEdit = async (event) => {
        event.preventDefault();
        const system_response = this.state.smsInfo.response;
        const sent_status = this.state.smsInfo.status;
        const id = this.state.smsInfo.id;

        axios.patch(`${LOCAL_URL}cargo/api/sms/${id}/`, {
            response: system_response,
            status: sent_status,
        }).then((res) => {
            alertService.info('Success! ' + res.data.name + ' edited successfully ', {
                autoClose: true,
                keepAfterRouteChange: false
            })
        }).catch(error => console.error(error));
        this.setState({open: false})
    }


    render() {
        const {
            deleteView,
            openView,
            open,
            sendView,
            smsz,
            previous,
            next,
            loading,
            searchTerm,
            smsInfo
        } = this.state;

        const handleClick = (sms) => {
            this.setState({open: true, smsInfo: sms});
        };

        const handleView = (sms) => {
            this.setState({openView: true, smsInfo: sms});
        };

        const handleSendView = () => {
            this.setState({sendView: true});
        };

        const handleDeleteView = (sms) => {
            this.setState({deleteView: true, smsInfo: sms});
        }

        const handleClose = () => {
            this.setState({open: false, openView: false, deleteView: false, sendView: false});
        };

        const {classes} = this.props;

        const renderDate = (sms) => {
            const created = sms.created_date;
            return created.substr(0, created.indexOf('T'));
        }

        const renderStatus = (sms) => {

            if (sms.status === 1) {
                return <span>
                     <Chip size="small" label="Sent"
                           color="success"/>
                </span>;
            } else if (sms.status === 2) {
                return <span>
                     <Chip size="small" label="Failed"
                           color="secondary"/>
                </span>;
            } else if (sms.status === 0) {
                return <span>
                     <Chip size="small" label="Pending"
                           color="primary"/>
                </span>;
            }
        }
        return (
            <div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 ">
                            <div className="header mt-md-5">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h1 className="header-title">
                                                <FontAwesomeIcon icon={faSms}/>&nbsp;&nbsp;&nbsp;
                                                All Sms
                                            </h1>
                                        </div>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className="col">
                                            <h3>
                                                Credits Balance: {this.state.balance}
                                            </h3>
                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Button variant="contained"
                                                        startIcon={<FontAwesomeIcon icon={faPaperPlane}/>}
                                                        onClick={() => handleSendView()}>Send Sms
                                                </Button>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/sms/new'}>
                                                    <Button variant="contained"
                                                            startIcon={<FontAwesomeIcon icon={faSms}/>}>
                                                        Quick Sms</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 ">
                            {loading ? (
                                <div className='text-center'>
                                    <div className='spinner-border text-primary align-content-center' role='status'>
                                        {' '}
                                        <span className='sr-only'>Loading...</span>{' '}
                                    </div>
                                </div>
                            ) : (
                                <div className="content">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <input
                                                name="searchTerm"
                                                id="searchTerm"
                                                type="text"
                                                placeholder="Mobile No"
                                                value={searchTerm}
                                                onChange={this.handleChange}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <button onClick={this.searchPage}>Search
                                            </button>
                                        </div>
                                        <div className='col-auto'>
                                            <div className="pagination">
                                                <button disabled={previous === null} onClick={this.prevPage}>Previous
                                                </button>
                                                &nbsp;&nbsp;
                                                <button disabled={next === null} onClick={this.nextPage}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <TableContainer className={classes.container} component={Paper}>
                                        <Table stickyHeader className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width="10%">Date</TableCell>
                                                    <TableCell width="13%">Customer</TableCell>
                                                    <TableCell width="13%">Phone </TableCell>
                                                    <TableCell width="10%">Type</TableCell>
                                                    <TableCell width="29%">Response</TableCell>
                                                    <TableCell width="10%">Status</TableCell>
                                                    <TableCell width="15%">Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {smsz.map((sms) => {
                                                    return <>
                                                        <TableRow key={sms.id}>
                                                            <TableCell>{renderDate(sms)}</TableCell>
                                                            <TableCell>{sms.customer_id.firstname}</TableCell>
                                                            <TableCell>{sms.sender_id}</TableCell>
                                                            <TableCell>{sms.type}</TableCell>
                                                            <TableCell>{sms.response}</TableCell>
                                                            <TableCell>{renderStatus(sms)}</TableCell>
                                                            <TableCell>
                                                                <Tooltip title="View">
                                                                    <IconButton aria-label="view"
                                                                                onClick={() => handleView(sms)}>
                                                                        <InfoIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Edit">
                                                                    <IconButton aria-label="update"
                                                                                onClick={() => handleClick(sms)}>
                                                                        <EditIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Delete">
                                                                    <IconButton aria-label="update"
                                                                                onClick={() => handleDeleteView(sms)}>
                                                                        <DeleteIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <Dialog
                        open={sendView}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <form onSubmit={this.handleSendSms} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Send Sms</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Notify customers on arrival of container.
                                </DialogContentText>
                                <DialogContentText>
                                    <div className='row'>
                                        <div className='col-12 col-md-12 col-xl-12'>
                                            <div className="form-group">
                                                <label htmlFor="id_customer">Select Container</label>
                                                <AsyncSelect
                                                    onChange={this.handleSelectContainer.bind(this)}
                                                    placeholder={'type something ...'}
                                                    loadOptions={this.loadContainer}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </DialogContentText>
                                <DialogContentText>
                                    Sms Template: Ndugu Mteja, mzigo wako umewasili kwenye godown letu.
                                    Tafadhali fika ofisini kwetu. Asante kwa kuichagua GNMCARGO.
                                </DialogContentText>
                                <DialogContentText>
                                    Attention: Please use this service wisely.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    No
                                </Button>
                                <Button
                                    startIcon={<FontAwesomeIcon icon={faPaperPlane}/>}
                                    type='submit'
                                    color="primary">
                                    Send
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={deleteView}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <form onSubmit={this.handleDelete} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Delete Sms</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete {smsInfo.customer_id.firstname}'s Sms?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    No
                                </Button>
                                <Button type='submit' color="primary">
                                    Yes
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <form onSubmit={this.handleEdit} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Edit Sms Message Details</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    To edit to this message, please edit the fields below.
                                </DialogContentText>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_customer">Name</label>
                                            <input
                                                type='text'
                                                name='customer_id'
                                                className='form-control'
                                                required='required'
                                                disabled='true'
                                                value={open ? smsInfo.customer_id.firstname : ""}
                                                id='id_customer'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_cellphone">Phone Number</label>
                                            <input
                                                type='number'
                                                name='sender_id'
                                                className='form-control'
                                                required='required'
                                                value={open ? smsInfo.sender_id : ""}
                                                id='id_cellphone'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-12 col-xl-12'>
                                        <div className="form-group">
                                            <label htmlFor="id_response">Response</label>
                                            <input
                                                type='text'
                                                name='response'
                                                className='form-control'
                                                required='required'
                                                disabled='true'
                                                value={open ? smsInfo.response : ""}
                                                id='id_response'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-12 col-xl-12'>
                                        <div className="form-group">
                                            <label htmlFor="id_request">Status</label>
                                            <select name="status" className="required form-control" required=""
                                                    id="id_unit" value={open ? smsInfo.status : ""}
                                                    onChange={this.handleChangeEdit}>
                                                <option value="" selected="">{open ? smsInfo.status : ""}</option>
                                                <option value="0">Pending</option>
                                                <option value="1">Sent</option>
                                            </select>
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    Cancel
                                </Button>
                                <Button type='submit' color="primary">
                                    Edit
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={openView}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <DialogTitle id="form-dialog-title"> About Sms Message
                        </DialogTitle>
                        <DialogContent>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Customer:</strong> {openView ? smsInfo.customer_id.firstname : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Phone Number:</strong> {openView ? smsInfo.sender_id : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Type:
                                        </strong> {openView ? smsInfo.type : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Response</strong> {openView ? smsInfo.response : ""}
                                    </Typography>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        )
    }
}

export default withStyles(useStyles)(ListSms);