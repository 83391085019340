import React, {Component} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import Chip from '@material-ui/core/Chip';
import {DataGrid} from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import HomeIcon from '@material-ui/icons/Home';
import {LOCAL_URL} from "../utils";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const EditDialog = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const EditDialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const EditDialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

class UsersListPage extends Component {
    state = {
        userList: [],
        isLoading: true,
        isOpen: false,
        modalInfo: {
            first_name: "",
            last_name: "",
            email: "",
            role: "",
            cellphone: "",
            password: "",
        }
    }
    handleChange = (event) => {
        console.log('name: ' + event.target.name);
        let newArr = this.state.modalInfo; // copying the old data array
        console.log(newArr)
        newArr[event.target.name] = event.target.value; // replace e.target.value with whatever you want to change it to
        this.setState(newArr);
    };


    componentDidMount() {
        let token = localStorage.getItem("token")
        console.log(token)
        axios.get(`${LOCAL_URL}cargo/users/auth/users/`, {
                headers: {
                    Authorization: "JWT " + localStorage.getItem("token")
                }
            }
        )
            .then(r => {
                const userList = r.data.users;
                console.log(userList)
                this.setState({isLoading: false, userList});
            })
            .then(() => {
                // this.props.handleSubmit();
            })
    }

    render() {
        const {
            userList,
            isOpen,
            modalInfo,
        } = this.state;

        const handleClickOpen = (params) => {
            const row: GridApi = params.row;
            this.setState({isOpen: true, modalInfo: row});
        };
        const handleCloseOpen = () => {
            this.setState({isOpen: false})
        };
        const renderRole = (params) => {
            if (params.value === 1) {
                return <span>
                     <Chip size="small" label="Admin"
                              color="primary"/>
                </span>;
            } else if (params.value === 2){
                return <span>
                     <Chip size="small" label="Manager"
                              color="primary"/>
                </span>;
            }else if (params.value === 3){
                return <span>
                     <Chip size="small" label="Employee"
                              color="primary"/>
                </span>;
            }
        }
        const columns = [
            {field: 'first_name', type: 'string', headerName: 'First Name', width: 220},
            {field: 'last_name', type: 'string', headerName: 'Last Name', width: 220},
            {field: 'email', type: 'string', headerName: 'Email', width: 220},
            {field: 'cellphone', type: 'string', headerName: 'Phone Number', width: 180},
            {
                field: "role",
                headerName: "Role",
                sortable: true,
                width: 140,
                type: 'string',
                renderCell: renderRole
            },
            {
                field: "",
                headerName: "Actions",
                sortable: false,
                width: 120,
                type: 'string',
                renderCell: (params: CellParams) => (
                    <span>
                        <Tooltip title="View">
                            <IconButton aria-label="view" onClick={e => handleClickOpen(params)}>
                              <InfoIcon fontSize="small"/>
                            </IconButton>
                        </Tooltip>
                    </span>
                )
            },
        ];
        return (
            <div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12">
                            <div className="header mt-md-5">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h1 className="header-title">
                                                <i className="fe fe-user"/>&nbsp;&nbsp;&nbsp;
                                                All Users
                                            </h1>
                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/users/new-user'}>
                                                    <Button variant="contained" startIcon={<AddIcon/>}>Add
                                                        User</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12">
                            {this.state.isLoading ? (
                                <div className='text-center'>
                                    <div className='spinner-border text-primary align-content-center' role='status'>
                                        {' '}
                                        <span className='sr-only'>Loading...</span>{' '}
                                    </div>
                                </div>
                            ) : (
                                <div style={{height: 550, width: '100%'}}>
                                    <DataGrid rows={userList} columns={columns}
                                    />
                                </div>

                            )}
                        </div>
                    </div>
                </div>

                <div>
                    <Dialog
                        onClose={handleCloseOpen}
                        aria-labelledby="customized-dialog-title"
                        open={isOpen}
                        maxWidth='md'
                        fullWidth={true}>
                        <EditDialog id="customized-dialog-title" onClose={handleCloseOpen}>
                            About User
                        </EditDialog>
                        <EditDialogContent dividers>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>First Name:</strong> {isOpen ? modalInfo.first_name : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Last Name:</strong> {isOpen ? modalInfo.last_name : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Email:</strong> {isOpen ? modalInfo.email : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Password:</strong> {isOpen ? modalInfo.password : ""}
                                    </Typography>
                                </div>
                            </div>
                        </EditDialogContent>
                        <EditDialogActions>
                            <Button autoFocus onClick={handleCloseOpen} color="secondary">
                                Close
                            </Button>
                        </EditDialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }
}

export default UsersListPage;
