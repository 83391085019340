import axios from 'axios'
import {SUPPLIERS_URL, ALL_SUPPLIERS_URL, SEARCH_SUPPLIERS_URL, DELETE_SUPPLIER, LOCAL_URL} from '../utils/index'
import React, {Component} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import {alertService} from "../_services/alert.service";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import AddIcon from "@material-ui/icons/Add";
import {ExportToExcel} from "../_components/ExportToExcel";
import Chip from "@material-ui/core/Chip";
import Paper from '@material-ui/core/Paper';
import {TableContainer, Tooltip} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import InfoIcon from "@material-ui/icons/Info";
import Typography from "@material-ui/core/Typography";

const useStyles = () => ({
    table: {
        minWidth: 1250,
    },
    container: {
        maxHeight: 800,
    },
});

class ListSupplier extends Component {
    state = {
        suppliers: [],
        supplierList: [],
        previous: '',
        next: '',
        loading: true,
        searchTerm: '',
        open: false,
        openView: false,
        deleteView: false,
        supplierInfo: {
            name: "",
            cellphone: "",
            id: "",
            address: "",
            status: "",
        }
    }

    componentDidMount() {
        this.loadPosts(SUPPLIERS_URL).then()
        this.loadSuppliers(ALL_SUPPLIERS_URL).then()
    }

    loadPosts = async (url) => {
        await axios.get(url)
            .then((res) => {
                const suppliers = res.data.results
                this.setState({
                    suppliers,
                    previous: res.data.previous,
                    next: res.data.next,
                    loading: false
                })
            })
    }

    loadSuppliers = async (url) => {
        await axios.get(url)
            .then((res) => {
                const list = res.data;
                const supplierList = list.map(obj => {
                        return {
                            date: obj.created_date.substr(0, obj.created_date.indexOf('T')),
                            supplier_name: obj.name,
                            cellphone: obj.cellphone,
                            address: obj.address,
                        }
                    }
                );
                this.setState({supplierList: supplierList})
            })
    }


    handleDelete = async (event) => {
        event.preventDefault();
        const supplier_id = this.state.supplierInfo.id;
        let DELETE_URL = DELETE_SUPPLIER + supplier_id;
        axios.delete(DELETE_URL)
            .then(() => {
                alertService.warn('Success! supplier successfully deleted ', {
                    autoClose: true,
                    keepAfterRouteChange: false
                })
                window.scrollTo(0, 0);
            });
        this.setState({deleteView: false})
    }


    prevPage = () => {
        this.setState({loading: true})

        if (this.state.previous !== null) {
            let http_link = this.state.previous
            let toPage = http_link.replace("http", "https")
            this.loadPosts(http_link).then();
        } else {
            this.loadPosts(this.state.previous).then();
        }
    }

    nextPage = () => {
        this.setState({loading: true})
        if (this.state.next !== null) {
            let http_link = this.state.next
            let toPage = http_link.replace("http", "https")
            this.loadPosts(http_link).then();
        } else {
            this.loadPosts(this.state.next).then();
        }
    }

    searchPage = () => {
        let term = this.state.searchTerm
        if (term !== "") {
            this.setState({loading: true})
            let toPage = SEARCH_SUPPLIERS_URL + term;
            this.loadPosts(toPage).then();
        }
    }
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleChangeEdit = (event) => {
        let newArr = this.state.supplierInfo; // copying the old data array
        newArr[event.target.name] = event.target.value; // replace e.target.value with whatever you want to change it to
        this.setState(newArr);
    };


    handleEdit = async (event) => {
        event.preventDefault();
        const supplier_name = this.state.supplierInfo.name;
        const cellphone = this.state.supplierInfo.cellphone;
        const company_name = this.state.supplierInfo.company_name
        const address = this.state.supplierInfo.address;
        const user = localStorage.getItem('user_id');
        const id = this.state.supplierInfo.id;

        axios.patch(`${LOCAL_URL}cargo/api/suppliers/${id}/`, {
            name: supplier_name,
            cellphone: cellphone,
            company_name: company_name,
            address: address,
            user: user
        }).then((res) => {
            alertService.info('Success! ' + res.data.name + ' edited successfully ', {
                autoClose: true,
                keepAfterRouteChange: false
            })
        }).catch(error => console.error(error));
        this.setState({open: false})
    }


    render() {
        const {
            supplierList,
            deleteView,
            openView,
            open,
            suppliers,
            previous,
            next,
            loading,
            searchTerm,
            supplierInfo
        } = this.state;
        const fileName = "supplier_list"; // here enter filename for your excel file

        const handleClick = (supplier) => {
            this.setState({open: true, supplierInfo: supplier});
        };

        const handleView = (supplier) => {
            this.setState({openView: true, supplierInfo: supplier});
        };

        const handleDeleteView = (supplier) => {
            this.setState({deleteView: true, supplierInfo: supplier});
        }

        const handleClose = () => {
            this.setState({open: false, openView: false, deleteView: false});
        };

        const {classes} = this.props;

        const renderDate = (supplier) => {
            const created = supplier.created_date;
            return created.substr(0, created.indexOf('T'));
        }

        const renderStatus = (supplier) => {

            if (supplier.status === 1) {
                return <span>
                     <Chip size="small" label="Active"
                           color="primary"/>
                </span>;
            } else if (supplier.status === 0) {
                return <span>
                     <Chip size="small" label="Inactive"
                           color="secondary"/>
                </span>;
            }
        }

        return (
            <div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 ">
                            <div className="header mt-md-5">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h1 className="header-title">
                                                <i className="fe fe-users"/>&nbsp;&nbsp;&nbsp;
                                                All Suppliers
                                            </h1>
                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/suppliers/new'}>
                                                    <Button variant="contained" startIcon={<AddIcon/>}>Add
                                                        Supplier</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <ExportToExcel apiData={supplierList} fileName={fileName}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 ">
                            {loading ? (
                                <div className='text-center'>
                                    <div className='spinner-border text-primary align-content-center' role='status'>
                                        {' '}
                                        <span className='sr-only'>Loading...</span>{' '}
                                    </div>
                                </div>
                            ) : (
                                <div className="content">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <input
                                                name="searchTerm"
                                                id="searchTerm"
                                                type="text"
                                                placeholder="Mobile No"
                                                value={searchTerm}
                                                onChange={this.handleChange}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <button onClick={this.searchPage}>Search
                                            </button>
                                        </div>
                                        <div className='col-auto'>
                                            <div className="pagination">
                                                <button disabled={previous === null} onClick={this.prevPage}>Previous
                                                </button>
                                                &nbsp;&nbsp;
                                                <button disabled={next === null} onClick={this.nextPage}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <TableContainer className={classes.container} component={Paper}>
                                        <Table stickyHeader className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Mobile Number</TableCell>
                                                    <TableCell>Address</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {suppliers.map((supplier) => {
                                                    return <>
                                                        <TableRow key={supplier.id}>
                                                            <TableCell>{renderDate(supplier)}</TableCell>
                                                            <TableCell>{supplier.name}</TableCell>
                                                            <TableCell>{supplier.cellphone}</TableCell>
                                                            <TableCell>{supplier.address}</TableCell>
                                                            <TableCell>{renderStatus(supplier)}</TableCell>
                                                            <TableCell>
                                                                <Tooltip title="View">
                                                                    <IconButton aria-label="view"
                                                                                onClick={() => handleView(supplier)}>
                                                                        <InfoIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Edit">
                                                                    <IconButton aria-label="update"
                                                                                onClick={() => handleClick(supplier)}>
                                                                        <EditIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Delete">
                                                                    <IconButton aria-label="update"
                                                                                onClick={() => handleDeleteView(supplier)}>
                                                                        <DeleteIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <Dialog
                        open={deleteView}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <form onSubmit={this.handleDelete} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Delete Supplier</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete {supplierInfo.name}?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    No
                                </Button>
                                <Button type='submit' color="primary">
                                    Yes
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <form onSubmit={this.handleEdit} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Edit Supplier Details</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    To edit to this supplier, please edit the fields below.
                                </DialogContentText>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_name">Name</label>
                                            <input
                                                type='text'
                                                name='name'
                                                className='form-control'
                                                required='required'
                                                value={open ? supplierInfo.name : ""}
                                                id='id_name'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_cellphone">Mobile Number</label>
                                            <input
                                                type='number'
                                                name='cellphone'
                                                className='form-control'
                                                required='required'
                                                value={open ? supplierInfo.cellphone : ""}
                                                id='id_cellphone'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-12 col-xl-12'>
                                        <div className="form-group">
                                            <label htmlFor="id_address">Address</label>
                                            <input
                                                type='text'
                                                name='address'
                                                className='form-control'
                                                required='required'
                                                value={open ? supplierInfo.address : ""}
                                                id='id_address'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    Cancel
                                </Button>
                                <Button type='submit' color="primary">
                                    Edit
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={openView}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <DialogTitle id="form-dialog-title"> About Supplier
                        </DialogTitle>
                        <DialogContent>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Supplier Name:</strong> {openView ? supplierInfo.name : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Phone Number:</strong> {openView ? supplierInfo.cellphone : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Address
                                        </strong> {openView ? supplierInfo.address : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Create
                                            By:</strong> {openView ? supplierInfo.user.first_name + " " + supplierInfo.user.last_name : ""}
                                    </Typography>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>

        )
    }
}

export default withStyles(useStyles)(ListSupplier);
