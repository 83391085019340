import React from 'react';
import ReactDOM from "react-dom";
import './index.css';
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import App from './App';
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import * as serviceWorker from './serviceWorker';

import authReducer from "./reducers/auth";

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: authReducer,
});

const store = createStore(rootReducer, composeEnhances(applyMiddleware(thunk)));

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
