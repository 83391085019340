import * as actionTypes from "./actionTypes";
import axios from "axios";
import {LOCAL_URL} from "../utils";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = token => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("full_name");
  localStorage.removeItem("user_id");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("expirationDate");
  return {
    type: actionTypes.AUTH_LOGOUT

  };
};

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const authLogin = (email, password) => {
  return dispatch => {
    dispatch(authStart());
    axios.post(`${LOCAL_URL}cargo/users/auth/login`, {
                email: email,
                password: password
            })
      .then
      (result => {
          const token = result.data.access;
          const refresh_token = result.data.refresh;
          const first_name = result.data.authenticatedUser.first_name;
          const last_name = result.data.authenticatedUser.last_name;
          const user_id = result.data.authenticatedUser.id;
          const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
          localStorage.setItem("token", token);
          localStorage.setItem("refresh_token", refresh_token);
          localStorage.setItem("expirationDate", expirationDate);
          localStorage.setItem("full_name", first_name + " " + last_name);
          localStorage.setItem("user_id", user_id );
          dispatch(authSuccess(token));
          dispatch(checkAuthTimeout(3600));
      })
      .catch(err => {
        dispatch(authFail(err));
      });
  };
};

// export const authSignup = (username, email, password1, password2) => {
//   return dispatch => {
//     dispatch(authStart());
//     axios
//       .post("http://127.0.0.1:8000/rest-auth/registration/", {
//         username: username,
//         email: email,
//         password1: password1,
//         password2: password2
//       })
//       .then(res => {
//         const token = res.data.key;
//         const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
//         localStorage.setItem("token", token);
//         localStorage.setItem("expirationDate", expirationDate);
//         dispatch(authSuccess(token));
//         dispatch(checkAuthTimeout(3600));
//       })
//       .catch(err => {
//         dispatch(authFail(err));
//       });
//   };
// };

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem("token");
    if (token === undefined) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        dispatch(authSuccess(token));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};
