import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Redirect} from "react-router";
import axios from "axios";
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import { alertService } from '../_services/alert.service';
import {LOCAL_URL} from "../utils";


class CreateWarehousePage extends Component {
    componentDidMount() {
        let user_id = localStorage.getItem('user_id');
        let access_token = localStorage.getItem("token")
        // console.log(user_id, access_token)
        this.setState({id: user_id})
        this.setState({token: access_token})
    }

    state = {
        name: "",
        location: "",
        address: "",
        errorMessage: "",
        autoClose: false,
        keepAfterRouteChange: false,
        redirectToNewPage: false
    };
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };
    handleSubmit = async (event) => {
        event.preventDefault();
        const name = this.state.name;
        const location = this.state.location;
        const address = this.state.address;
        const user = localStorage.getItem('user_id');
        // console.log(name, location, address, user);
        axios.post(`${LOCAL_URL}cargo/api/warehouses/`, {
            name: name,
            location: location,
            address: address,
            user: user
        }).then((response) => {
            if (response.data.status === 0) {
                this.setState({redirectToNewPage: true});
                alertService.success('Success! Warehouse successfully added', { autoClose:true , keepAfterRouteChange: false })
            }
        }).catch(err => {
            this.setState({errorMessage: err.message})
            alertService.error('Error :( '+ err.message, { autoClose:true , keepAfterRouteChange: false })
        })
    };

    render() {
        const {name, location, address} = this.state;
        if (this.state.redirectToNewPage) {
            return (
                <Redirect to="/warehouses"/>
            )
        }
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10 col-xl-8">
                            {/*{this.state.errorMessage &&*/}
                            {/*<h3 className="error"> {this.state.errorMessage} </h3>}*/}
                            <div className="header mt-md-5">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">

                                            <h1 className="header-title">
                                                <i className="fe fe-home"/>&nbsp;&nbsp;&nbsp;
                                                Add a Warehouse
                                            </h1>

                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/warehouses'}>
                                                    <Button variant="contained" startIcon={<HomeWorkIcon/>}>
                                                        Warehouses</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <form onSubmit={this.handleSubmit} className="organizer-form">
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label for="id_name">Warehouse Name</label>
                                            <input
                                                type='text'
                                                name='name'
                                                placeholder='ex: GNM Ilala Warehouse'
                                                className='form-control'
                                                required='required'
                                                value={name}
                                                id='id_name'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label for="id_location">Location</label>
                                            <input
                                                type='text'
                                                name='location'
                                                placeholder='ex: Dar es Salaam'
                                                className='form-control'
                                                required='required'
                                                value={location}
                                                id='id_location'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-12 col-xl-12'>
                                        <div className="form-group">
                                            <label htmlFor="id_address">Address</label>
                                            <input
                                                type='text'
                                                name='address'
                                                placeholder='ex: Kariakoo Ilala DSM Tanzania'
                                                className='form-control'
                                                required='required'
                                                value={address}
                                                id='id_address'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    className='primaryAction btn btn-lg btn-block btn-primary mb-3'
                                    type='submit'
                                >
                                    Create Warehouse
                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CreateWarehousePage;
