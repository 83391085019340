import React from "react";

const GoodList = props => {
    return props.goodDetails.map((val, idx) => {
        let name = `name-${idx}`,
            pckg = `pckg-${idx}`,
            quantity = `quantity-${idx}`,
            unit = `unit-${idx}`;
        return (
            <div className="row" key={val.index}>
                <div className="col p-4">
                    {idx === 0 ? (
                        <button
                            onClick={() => props.add()}
                            type="button"
                            className="btn btn-primary text-center"
                        >
                            <i className="fe fe-plus-circle" aria-hidden="true"/>
                        </button>
                    ) : (
                        <button
                            className="btn btn-danger"
                            onClick={() => props.delete(val)}
                        >
                            <i className="fe fe-minus-circle" aria-hidden="true"/>
                        </button>
                    )}
                </div>
                <div className='col-12 col-md-3 col-xl-3'>
                    <div className="form-group">
                        <label>Name of Goods</label>
                        <input
                            type="text"
                            className="form-control required"
                            placeholder="Handbag"
                            required='required'
                            name="name"
                            data-id={idx}
                            id={name}
                            value={props.goodDetails[idx].name}
                        />
                    </div>
                </div>
                <div className='col-12 col-md-2 col-xl-2'>
                    <div className="form-group">
                        <label>PKGS</label>
                        <input
                            type="number"
                            className="form-control required"
                            placeholder="10"
                            required='required'

                            name="pckg"
                            id={pckg}
                            data-id={idx}
                            value={props.goodDetails[idx].pckg}
                        />
                    </div>
                </div>
                <div className='col-12 col-md-3 col-xl-3'>
                    <div className="form-group">
                        <label>Unit</label>
                        <select className="form-control" name="unit" id={unit} data-id={idx}
                                value={props.goodDetails[idx].unit} required='required' defaultValue="pieces"
                        >
                            <option value="Pieces">Pieces</option>
                            <option value="Prs">Prs</option>
                            <option value="Set">Set</option>
                            <option value="Bale">Bale</option>
                            <option value="Boxes">Boxes</option>
                            <option value="Cartons">Cartons</option>
                            <option value="Dozen">Dozen</option>
                            <option value="Package">Package</option>
                        </select>
                    </div>
                </div>
                <div className='col-12 col-md-3 col-xl-3'>
                    <div className="form-group">
                        <label>Quantity</label>
                        <input
                            type="number"
                            className="form-control"
                            placeholder="10000"
                            name="quantity"
                            required='required'

                            id={quantity}
                            data-id={idx}
                            value={props.goodDetails[idx].quantity}
                        />
                    </div>
                </div>

            </div>
        );
    });
};
export default GoodList;
