export const LOCAL_URL = 'https://cmts-api.firstvisioncargo.com/'

// export const LOCAL_URL = 'http://127.0.0.1:6600/'
export const CONSIGNMENTS_URL = `${LOCAL_URL}cargo/api/list_consignments/`
export const DELETE_CONSIGNMENT = `${LOCAL_URL}cargo/api/consignments`
export const ALL_CONSIGNMENTS_URL = `${LOCAL_URL}cargo/api/all_consignments`
export const SEARCH_CONSIGNMENTS_URL = `${LOCAL_URL}cargo/api/list_consignments/?search=`

export const GOODS_URL = `${LOCAL_URL}cargo/api/list_goods/`
export const DELETE_GOOD = `${LOCAL_URL}cargo/api/goods/`
export const SEARCH_GOODS_URL = `${LOCAL_URL}cargo/api/list_goods/?search=`

export const DELETE_GOOD_CONSIGNMENTS = `${LOCAL_URL}cargo/api/good_consignments/`

export const CUSTOMERS_URL = `${LOCAL_URL}cargo/api/list_customers/`
export const ALL_CUSTOMERS_URL = `${LOCAL_URL}cargo/api/all_customers/`
export const DELETE_CUSTOMERS = `${LOCAL_URL}cargo/api/customers/`
export const SEARCH_CUSTOMERS_URL = `${LOCAL_URL}cargo/api/list_customers/?search=`

export const SHIPMENTS_URL = `${LOCAL_URL}cargo/api/list_shipments/`
export const DELETE_SHIPMENT = `${LOCAL_URL}cargo/api/shipments/`
// export const ALL_SHIPMENTS_URL = 'cargo/api/all_shipments/'
export const SEARCH_SHIPMENTS_URL = `${LOCAL_URL}cargo/api/list_shipments/?search=`

export const CONTAINERS_URL = `${LOCAL_URL}cargo/api/list_containers/`
export const DELETE_CONTAINER = `${LOCAL_URL}cargo/api/containers/`
export const SEARCH_CONTAINER_URL = `${LOCAL_URL}cargo/api/list_containers/?search=`

export const SUPPLIERS_URL = `${LOCAL_URL}cargo/api/list_suppliers/`
export const DELETE_SUPPLIER = `${LOCAL_URL}cargo/api/suppliers/`
export const ALL_SUPPLIERS_URL = `${LOCAL_URL}cargo/api/all_suppliers/`
export const SEARCH_SUPPLIERS_URL = `${LOCAL_URL}cargo/api/list_suppliers/?search=`

export const WHATSAPP_URL = `${LOCAL_URL}cargo/api/list_whatsapp/`
export const DELETE_WHATSAPP = `${LOCAL_URL}cargo/api/whatsapp/`
// export const ALL_WHATSAPP_URL = 'cargo/api/all_whatsapp/'
export const SEARCH_WHATSAPP_URL = `${LOCAL_URL}cargo/api/list_whatsapp/?search=`

export const SMS_URL = `${LOCAL_URL}cargo/api/list_sms/`
export const DELETE_SMS = `${LOCAL_URL}cargo/api/sms/`
// export const ALL_SMS_URL = 'cargo/api/all_sms/'
export const SEARCH_SMS_URL = `${LOCAL_URL}cargo/api/list_sms/?search=`

export const username = "87ece8c38d94d892"
export const password = "ZmU5MDg4NGRiYzg0Mzg5ZWIwMjAxODMwMzFiYjgxMTlkOWUxNmU5N2E3ODEyNzg3MmUyMWExM2UxYTA5YzhlNw=="
