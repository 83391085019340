import React, {Component} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {DataGrid} from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import HomeIcon from '@material-ui/icons/Home';
import {LOCAL_URL} from "../utils";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const EditDialog = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const EditDialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const EditDialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

class WarehouseListPage extends Component {
    state = {
        warehouseList: [],
        isLoading: true,
        isOpen: false,
        isDeactivate: false,
        isEdit: false,
        modalInfo: {
            name: "",
            location: "",
            address: "",
            id: "",
            status: "",
        }
    }
    handleChange = (event) => {
        console.log('name: ' + event.target.name);
        let newArr = this.state.modalInfo; // copying the old data array
        console.log(newArr)
        newArr[event.target.name] = event.target.value; // replace e.target.value with whatever you want to change it to
        this.setState(newArr);
    };
    handleEdit = async (event) => {
        event.preventDefault();

        // const { name, location, address } = this.state;
        const name = this.state.modalInfo.name;
        const location = this.state.modalInfo.location;
        const address = this.state.modalInfo.address;
        const status = this.state.modalInfo.status
        const user = localStorage.getItem('user_id');
        const id = this.state.modalInfo.id;
        console.log(id, name, location, address, user);

        axios.put(`${LOCAL_URL}cargo/api/warehouses/${id}/`, {
            name: name,
            location: location,
            address: address,
            status: status,
            user: user
        }).then(res => console.log(res)).catch(error => console.error(error));

        this.setState({isEdit: false})
    };

    handleDeactivate = async (event) => {
        event.preventDefault();

        // const { name, location, address } = this.state;
        const status = this.state.modalInfo.status
        const user = localStorage.getItem('user_id');
        const id = this.state.modalInfo.id;
        console.log(id, status, user);

        axios.patch(`${LOCAL_URL}cargo/api/warehouses/${id}/`, {
            status: status,
            user: user
        }).then(res => console.log(res)).catch(error => console.error(error));

        this.setState({isDeactivate: false})
    };

    componentDidMount() {
        axios.get(`${LOCAL_URL}cargo/api/all_warehouses/`)
            .then(r => {
                const warehouseList = r.data;
                this.setState({isLoading: false, warehouseList});
            })
            .then(() => {
                // this.props.handleSubmit();
            })
    }

    render() {
        const {
            warehouseList,
            isOpen,
            isDeactivate,
            isEdit,
            modalInfo,
        } = this.state;
        const handleClickEdit = (params) => {
            const row: GridApi = params.row;
            this.setState({isEdit: true, modalInfo: row});
        };
        const handleCloseEdit = () => {
            this.setState({isEdit: false})
        };
        const handleClickDeactivate = (params) => {
            const row: GridApi = params.row;
            this.setState({isDeactivate: true, modalInfo: row});
        };
        const handleCloseDeactivate = () => {
            this.setState({isDeactivate: false})
        };
        const handleClickOpen = (params) => {
            const row: GridApi = params.row;
            this.setState({isOpen: true, modalInfo: row});
        };
        const handleCloseOpen = () => {
            this.setState({isOpen: false})
        };
        const renderStatus = (params) => {
            if (params.value === 1) {
                return <span>
                     <Chip size="small" label="Active"
                              color="primary"/>
                </span>;
            } else if (params.value === 0){
                return <span>
                     <Chip size="small" label="Inactive"
                              color="secondary"/>
                </span>;
            }
        }
        const columns = [
            {field: 'name', type: 'string', headerName: 'Name', width: 290},
            {field: 'location', type: 'string', headerName: 'Location', width: 250},
            {field: 'address', type: 'string', headerName: 'Physical Address', width: 320},
            {
                field: "status",
                headerName: "Status",
                sortable: true,
                width: 100,
                type: 'string',
                renderCell: renderStatus
            },
            {
                field: "",
                headerName: "Actions",
                sortable: false,
                width: 150,
                type: 'string',
                renderCell: (params: CellParams) => (
                    <span>
                        <Tooltip title="View">
                            <IconButton aria-label="view" onClick={e => handleClickOpen(params)}>
                              <InfoIcon fontSize="small"/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                        <IconButton aria-label="edit" onClick={e => handleClickEdit(params)}>
                          <EditIcon fontSize="small"/>
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Deactivate">
                        <IconButton aria-label="deactivate" onClick={e => handleClickDeactivate(params)}>
                          <BlockIcon fontSize="small"/>
                        </IconButton>
                        </Tooltip>
                    </span>
                )
            },
        ];
        return (
            <div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 ">
                            <div className="header mt-md-5">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h1 className="header-title">
                                                <i className="fe fe-home"/>&nbsp;&nbsp;&nbsp;
                                                All Warehouses
                                            </h1>
                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/warehouses/new'}>
                                                    <Button variant="contained" startIcon={<AddIcon/>}>Add
                                                        Warehouse</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 ">
                            {this.state.isLoading ? (
                                <div className='text-center'>
                                    <div className='spinner-border text-primary align-content-center' role='status'>
                                        {' '}
                                        <span className='sr-only'>Loading...</span>{' '}
                                    </div>
                                </div>
                            ) : (
                                <div style={{height: 550, width: '100%'}}>
                                    <DataGrid rows={warehouseList} columns={columns}
                                    />
                                </div>

                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <Dialog open={isEdit} onClose={handleCloseEdit} aria-labelledby="form-dialog-title" maxWidth='md'
                            fullWidth={true}>
                        <form onSubmit={this.handleEdit} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Edit Warehouse</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    To edit to this warehouse, please edit the fields below.
                                </DialogContentText>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_name">Warehouse Name</label>
                                            <input
                                                type='text'
                                                name='name'
                                                className='form-control'
                                                required='required'
                                                value={isEdit ? modalInfo.name : ""}
                                                id='id_name'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_location">Location</label>
                                            <input
                                                type='text'
                                                name='location'
                                                className='form-control'
                                                required='required'
                                                value={isEdit ? modalInfo.location : ""}
                                                id='id_location'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-12 col-xl-12'>
                                        <div className="form-group">
                                            <label htmlFor="id_address">Address</label>
                                            <input
                                                type='text'
                                                name='address'
                                                className='form-control'
                                                required='required'
                                                value={isEdit ? modalInfo.address : ""}
                                                id='id_address'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseEdit} color="secondary">
                                    Cancel
                                </Button>
                                <Button type='submit' color="primary">
                                    Edit
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={isDeactivate}
                        onClose={handleCloseDeactivate}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        maxWidth='sm' fullWidth={true}
                    >
                        <form onSubmit={this.handleDeactivate} className="organizer-form">
                            <DialogTitle id="alert-dialog-slide-title">{"Deactivate Warehouse"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    You are about to deactivate {isDeactivate ? modalInfo.name : ""}.
                                </DialogContentText>
                                <div className="form-group">
                                    <label htmlFor="status">Status</label>
                                    <select name="status" className="required form-control" required=""
                                            id="id_type" value={isDeactivate ? modalInfo.status : ""}
                                            onChange={this.handleChange}>
                                        <option value="0"> Inactive</option>
                                        <option value="1">Active</option>
                                    </select>
                                    <div className="invalid-feedback">
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDeactivate} color="primary">
                                    Cancel
                                </Button>
                                <Button type='submit' color="primary">
                                    Proceed
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        onClose={handleCloseOpen}
                        aria-labelledby="customized-dialog-title"
                        open={isOpen}
                        maxWidth='md'
                        fullWidth={true}>
                        <EditDialog id="customized-dialog-title" onClose={handleCloseOpen}>
                            About Warehouse
                        </EditDialog>
                        <EditDialogContent dividers>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Name:</strong> {isOpen ? modalInfo.name : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Location:</strong> {isOpen ? modalInfo.location : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Physical Address:</strong> {isOpen ? modalInfo.address : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Created Date:</strong> {isOpen ? modalInfo.created_date : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Created
                                            By:</strong> {isOpen ? modalInfo.user.first_name + " " + modalInfo.user.last_name : ""}
                                    </Typography>
                                </div>
                            </div>
                        </EditDialogContent>
                        <EditDialogActions>
                            <Button autoFocus onClick={handleCloseOpen} color="secondary">
                                Close
                            </Button>
                        </EditDialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }
}

export default WarehouseListPage;
