import axios from 'axios'
import {DELETE_CONTAINER, CONTAINERS_URL, SEARCH_CONTAINER_URL, LOCAL_URL} from '../utils/index'
import React, {Component} from 'react';
import Select from 'react-select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import {alertService} from "../_services/alert.service";
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import teal from '@material-ui/core/colors/teal';
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AddIcon from "@material-ui/icons/Add";
import Chip from "@material-ui/core/Chip";
import Paper from '@material-ui/core/Paper';
import {TableContainer, Tooltip} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from '@material-ui/icons/Description';
// import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import InfoIcon from "@material-ui/icons/Info";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {DataGrid} from '@material-ui/data-grid';
import Alert from '@material-ui/lab/Alert';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = () => ({
    table: {
        minWidth: 1400,
    },
    container: {
        maxHeight: 800,
    },
});

const customStyle = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ListContainer extends Component {
    state = {
        containers: [],
        containerList: [],
        selectCustomers: [],
        containerDetails: [],
        customer_id: '',
        customer_name: '',
        previous: '',
        next: '',
        loading_customers: true,
        loading_data: true,
        loading: true,
        searchTerm: '',
        startDate: '',
        endDate: '',
        open: false,
        openView: false,
        deleteView: false,
        openPackingList: false,
        openCustomerPackingList: false,
        oldCustomerPackingList: false,
        containerInfo: {
            id: "",
            container_no: "",
            shipping_line: "",
            destination: "",
            origin: "",
            shipping_date: "",
            arrival_date: "",
            seal_no: "",
            volume: "",
            shipping_status: ""
        }
    }

    componentDidMount() {
        this.loadPosts(CONTAINERS_URL).then()
    }

    loadPosts = async (url) => {
        await axios.get(url)
            .then((res) => {
                const containers = res.data.results
                this.setState({
                    containers,
                    previous: res.data.previous,
                    next: res.data.next,
                    loading: false
                })
            })
    }

    handleDelete = async (event) => {
        event.preventDefault();
        const container_id = this.state.containerInfo.id;
        const container_name = this.state.containerInfo.container_no;
        let DELETE_URL = DELETE_CONTAINER + container_id;
        axios.delete(DELETE_URL)
            .then(() => {
                alertService.warn('Success! Container No  ' + container_name + 'successfully deleted ', {
                    autoClose: true,
                    keepAfterRouteChange: false
                })
                window.scrollTo(0, 0);
            });
        this.setState({deleteView: false})
    }


    prevPage = () => {
        this.setState({loading: true})

        if (this.state.previous !== null) {
            let http_link = this.state.previous
            let toPage = http_link.replace("http", "https")
            this.loadPosts(http_link).then();
        } else {
            this.loadPosts(this.state.previous).then();
        }
    }

    nextPage = () => {
        this.setState({loading: true})
        if (this.state.next !== null) {
            let http_link = this.state.next
            let toPage = http_link.replace("http", "https")
            this.loadPosts(http_link).then();
        } else {
            this.loadPosts(this.state.next).then();
        }
    }

    searchPage = () => {
        let term = this.state.searchTerm
        if (term !== "") {
            this.setState({loading: true})
            let toPage = SEARCH_CONTAINER_URL + term;
            this.loadPosts(toPage).then();
        }
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleChangeEdit = (event) => {
        let newArr = this.state.containerInfo; // copying the old data array
        newArr[event.target.name] = event.target.value; // replace e.target.value with whatever you want to change it to
        this.setState(newArr);
    };

    handleSelectCustomer = (e) => {
        this.setState({customer_id: e.value, customer_name: e.label})
    };

    ExportToExcel = async () => {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        if (startDate !== "" && endDate !== "") {
            await axios.get(`${LOCAL_URL}cargo/api/all_containers/?start=${startDate}&end=${endDate}`)
                .then((res) => {
                    const list = res.data;
                    const containerList = list.map(obj => {
                            return {
                                date: obj.created_date.substr(0, obj.created_date.indexOf('T')),
                                container_no: obj.container_no,
                                shipping_line: obj.shipping_line,
                                destination: obj.destination,
                                origin: obj.origin,
                                shipping_date: obj.shipping_date,
                                arrival_date: obj.arrival_date,
                                seal_no: obj.seal_no,
                                volume: obj.volume,
                                shipping_status: obj.shipping_status
                            }
                        }
                    );
                    this.setState({containerList: containerList})
                })
            const fileName = "containers_list_from_" + startDate + "_to_" + endDate
            const fileType =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const fileExtension = ".xlsx";
            const ws = XLSX.utils.json_to_sheet(this.state.goodList);
            const wb = {Sheets: {data: ws}, SheetNames: ["data"]};
            const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, fileName + fileExtension);
        }
    };

    handleEdit = async (event) => {
        event.preventDefault();
        const container_no = this.state.containerInfo.container_no;
        const shipping_line = this.state.containerInfo.shipping_line;
        const destination = this.state.containerInfo.destination;
        const origin = this.state.containerInfo.origin;
        const shipping_date = this.state.containerInfo.shipping_date;
        const arrival_date = this.state.containerInfo.arrival_date;
        const seal_no = this.state.containerInfo.seal_no;
        const volume = this.state.containerInfo.volume;
        const shipping_status = this.state.containerInfo.shipping_status
        const user = localStorage.getItem('user_id');
        const id = this.state.containerInfo.id;

        axios.put(`${LOCAL_URL}cargo/api/containers/${id}/`, {
            container_no: container_no,
            shipping_line: shipping_line,
            destination: destination,
            origin: origin,
            shipping_date: shipping_date,
            arrival_date: arrival_date,
            seal_no: seal_no,
            volume: volume,
            shipping_status: shipping_status,
            user: user
        }).then().catch(error => console.error(error));
        this.setState({open: false})
    }
    _onStartFocus = (e) => {
        e.currentTarget.type = "date";
    }
    _onEndFocus = (e) => {
        e.currentTarget.type = "date";
    }
    _onStartDate = (e) => {
        e.currentTarget.type = "text";
        e.currentTarget.placeholder = "Start Date";
    }
    _onEndDate = (e) => {
        e.currentTarget.type = "text";
        e.currentTarget.placeholder = "End Date";
    }

    buildTableBody(data, columns, customer_name, customer_phone, sum_pkgs, sum_cbm, sum_amount) {
        let body = [];
        const rowData1 = [
            {
                text: 'NAME: ' + customer_name,
                border: [true, false, true, true],
                style: 'tableHeader',
                colSpan: 4
            }, {}, {}, {},
            {
                text: 'TOTAL PKGS: ' + sum_pkgs,
                border: [true, false, true, true],
                style: 'tableHeader',
                colSpan: 4
            }, {}, {}, {}
        ]
        const rowData2 = [
            {text: 'TEL: ' + customer_phone, style: 'tableHeader', colSpan: 4}, {}, {}, {},
            {text: 'PAYMENT IV NO: ', style: 'tableHeader', colSpan: 4}, {}, {}, {}
        ]
        const rowData3 = [
            {text: 'CBM: ' + sum_cbm, style: 'tableHeader', colSpan: 4}, {}, {}, {},
            {text: 'TOTAL AMOUNT: USD ' + sum_amount, style: 'tableHeader', colSpan: 4}, {}, {}, {}
        ]
        body.push(rowData1);
        body.push(rowData2);
        body.push(rowData3);
        body.push(columns);

        data.forEach(function (row) {
            let dataRow = [];

            columns.forEach(function (column) {
                dataRow.push(row[column]);
            })

            body.push(dataRow);
        });

        return body;
    }

    table(data, columns, customer_name, customer_phone, sum_pkgs, sum_cbm, sum_amount) {
        return {
            table: {
                headerRows: 1,
                style: 'consignmentTable',
                widths: [35, 60, 35, 50, '*', 60, 35, 60],
                body: this.buildTableBody(data, columns, customer_name, customer_phone, sum_pkgs, sum_cbm, sum_amount)
            }
        };
    }

    handleCustomerPackingListPdf = async (event) => {
        event.preventDefault();
        const container_id = this.state.containerInfo.id
        console.log(container_id);
        const removeDuplicates = (duplicates) => {
            const flag = {};
            const unique = [];
            duplicates.forEach(elem => {
                if (!flag[elem.consignment.id]) {
                    flag[elem.consignment.id] = true;
                    unique.push(elem);
                }
            });
            return unique;
        }
        let sum = a => a.reduce((x, y) => x + y);
        axios.get(`${LOCAL_URL}cargo/api/get_shipped_container/?c=` + container_id).then((response) => {
            const r = response.data;
            const c_no = this.state.containerInfo.container_no
            // let total_amount
            // const unique_consignment = removeDuplicates(r);
            // total_amount = (sum(unique_consignment.map(x => Number(x.consignment.shipping_charge)))).toLocaleString();

            if (r.length > 0) {
                const rowData1 = [
                    {text: 'PORT OF DISCHARGE', style: 'tableHeader'},
                    this.state.containerInfo.destination,
                ]
                const rowData2 = [
                    {text: 'LOADING DATE', style: 'tableHeader'},
                    this.state.containerInfo.shipping_date,
                ]
                const rowData3 = [
                    {text: 'ARRIVAL DATE', style: 'tableHeader'},
                    this.state.containerInfo.arrival_date,
                ]

                let docDefinition = {
                    content: [
                        {
                            text: 'FIRST VISION CARGO CUSTOMER PACKING LIST',
                            style: 'header',
                            alignment: 'center'
                        },
                        {
                            style: 'consignmentTable',
                            table: {
                                widths: [207, '*'],
                                body: [
                                    rowData1,
                                    rowData2,
                                    rowData3,
                                ]
                            }
                        }
                    ],
                    styles: {
                        header: {
                            fontSize: 14,
                            bold: true,
                            margin: [0, 0, 0, 10]
                        },
                        subheader: {
                            fontSize: 12,
                            bold: true,
                            margin: [0, 10, 0, 5]
                        },
                        quote: {
                            italics: true
                        },
                        small: {
                            fontSize: 11
                        },
                        consignmentTable: {
                            margin: [0, 5, 0, 0]
                        },
                        tableHeader: {
                            bold: true,
                            fontSize: 12,
                            color: 'black'
                        }

                    }
                }
                //push tables
                let consignmentDataRows = [];
                let same_consignment = [];
                let customer_name = '';
                let customer_phone = '';
                let sum_pkgs
                let sum_cbm
                let sum_amount

                const data_filter = r.filter(element => element.customer.id === this.state.customer_id);
                // Get Same Consignments
                // Array of consignment ids
                const unique_consignment = removeDuplicates(data_filter);
                sum_pkgs = (sum(unique_consignment.map(x => Number(x.consignment.pkgs)))).toLocaleString();
                sum_cbm = (sum(unique_consignment.map(x => Number(x.consignment.cbm)))).toLocaleString();
                sum_amount = (sum(unique_consignment.map(x => Number(x.consignment.shipping_charge)))).toLocaleString();

                const unique = [...new Set(data_filter.map(item => item.consignment.id))];
                unique.forEach((item_consignment) => {
                    const consignment_filter = data_filter.filter(
                        element => element.consignment.id === item_consignment);
                    same_consignment.push(consignment_filter);
                })
                same_consignment.forEach(consignment_goods => {
                    for (let i = 0; i < consignment_goods.length; i++) {
                        customer_name = consignment_goods[i].customer.firstname;
                        customer_phone = consignment_goods[i].customer.cellphone;
                        consignmentDataRows.push(
                            {
                                PKGS: {
                                    text: consignment_goods[i].consignment.pkgs,
                                    rowSpan: consignment_goods.length,
                                    style: 'small'
                                },
                                BN: {
                                    text: consignment_goods[i].consignment.label,
                                    rowSpan: consignment_goods.length,
                                    style: 'small'
                                },
                                QTY: {text: consignment_goods[i].quantity, style: 'small'},
                                UNIT: {text: consignment_goods[i].unit, style: 'small'},
                                DESCRIPTION: {text: consignment_goods[i].good.name, style: 'small'},
                                RECEIPT: {text: consignment_goods[i].good.supplier_receipt_no, style: 'small'},
                                CBM: {
                                    text: consignment_goods[i].consignment.cbm,
                                    rowSpan: consignment_goods.length,
                                    style: 'small'
                                },
                                AMOUNT: {
                                    text: consignment_goods[i].consignment.shipping_charge,
                                    rowSpan: consignment_goods.length,
                                    style: 'small'
                                },
                            }
                        )
                    }
                });

                docDefinition.content.push(
                    this.table(consignmentDataRows,
                        ['PKGS',
                            'BN',
                            'QTY',
                            'UNIT',
                            'DESCRIPTION',
                            'RECEIPT',
                            'CBM',
                            'AMOUNT'
                        ],
                        customer_name,
                        customer_phone,
                        sum_pkgs,
                        sum_cbm,
                        sum_amount)
                );
                pdfMake.createPdf(docDefinition).download(`packing_list_${c_no}_${this.state.customer_name}.pdf`);

            } else {
                alertService.warn('Sorry, We can not generate packing list because container is empty', {
                    autoClose: true,
                    keepAfterRouteChange: false
                })
            }
        });
        this.setState({openCustomerPackingList: false, oldCustomerPackingList: false})
    }

    handleSpecial = (params) => {
        const row: GridApi = params.row;
        console.log(row.id);
        this.setState({customer_id: row.id, customer_name: row.name});
        this.handleCustomerPackingListPdf.bind(this);
    }

    handleGeneratePdf = async (event) => {
        event.preventDefault();
        const container_id = this.state.containerInfo.id
        const removeDuplicates = (duplicates) => {
            const flag = {};
            const unique = [];
            duplicates.forEach(elem => {
                if (!flag[elem.consignment.id]) {
                    flag[elem.consignment.id] = true;
                    unique.push(elem);
                }
            });
            return unique;
        }
        let sum = a => a.reduce((x, y) => x + y);
        axios.get(`${LOCAL_URL}cargo/api/get_shipped_container/?c=` + container_id).then((response) => {
            const r = response.data;
            const c_no = this.state.containerInfo.container_no
            let total_amount
            const unique_consignment = removeDuplicates(r);
            total_amount = (sum(unique_consignment.map(x => Number(x.consignment.shipping_charge)))).toLocaleString();

            if (r.length > 0) {
                const rowData1 = [
                    {text: 'PORT OF DISCHARGE', style: 'tableHeader'},
                    this.state.containerInfo.destination,
                    {text: 'SEAL NO', style: 'tableHeader'},
                    this.state.containerInfo.seal_no,
                ]
                const rowData2 = [
                    {text: 'CONTAINER NO', style: 'tableHeader'},
                    c_no,
                    {text: 'VOLUME', style: 'tableHeader'},
                    this.state.containerInfo.volume,
                ]
                const rowData3 = [
                    {text: 'LOADING DATE', style: 'tableHeader'},
                    this.state.containerInfo.shipping_date,
                    {text: 'SHIPPING LINE', style: 'tableHeader'},
                    this.state.containerInfo.shipping_line,
                ]
                const rowData4 = [
                    {text: 'ARRIVAL DATE', style: 'tableHeader'},
                    this.state.containerInfo.arrival_date,
                    {text: 'OCEAN FREIGHT/USD', style: 'tableHeader'},
                    "USD " + total_amount,
                ]
                //
                let flags = [], output = [], l = r.length, i;
                for (i = 0; i < l; i++) {
                    if (flags[r[i].customer.id]) continue;
                    flags[r[i].customer.id] = true;
                    output.push(r[i].customer.id);
                }


                let docDefinition = {
                    content: [
                        {
                            text: 'FIRST VISION CARGO PACKING LIST',
                            style: 'header',
                            alignment: 'center'
                        },
                        {
                            style: 'consignmentTable',
                            table: {
                                widths: ['*', 120, '*', 120],
                                body: [
                                    rowData1,
                                    rowData2,
                                    rowData3,
                                    rowData4
                                ]
                            }
                        }
                    ],
                    styles: {
                        header: {
                            fontSize: 14,
                            bold: true,
                            margin: [0, 0, 0, 10]
                        },
                        subheader: {
                            fontSize: 12,
                            bold: true,
                            margin: [0, 10, 0, 5]
                        },
                        quote: {
                            italics: true
                        },
                        small: {
                            fontSize: 11
                        },
                        consignmentTable: {
                            margin: [0, 5, 0, 0]
                        },
                        tableHeader: {
                            bold: true,
                            fontSize: 12,
                            color: 'black'
                        }

                    }
                }

                //push tables
                output.forEach((customer) => {
                    let consignmentDataRows = [];
                    let same_consignment = [];
                    let customer_name = '';
                    let customer_phone = '';
                    let sum_pkgs
                    let sum_cbm
                    let sum_amount

                    const data_filter = r.filter(element => element.customer.id === customer);
                    // Get Same Consignments
                    // Array of consignment ids
                    const unique_consignment = removeDuplicates(data_filter);
                    sum_pkgs = (sum(unique_consignment.map(x => Number(x.consignment.pkgs)))).toLocaleString();
                    sum_cbm = (sum(unique_consignment.map(x => Number(x.consignment.cbm)))).toLocaleString();
                    sum_amount = (sum(unique_consignment.map(x => Number(x.consignment.shipping_charge)))).toLocaleString();

                    const unique = [...new Set(data_filter.map(item => item.consignment.id))];
                    unique.forEach((item_consignment) => {
                        const consignment_filter = data_filter.filter(
                            element => element.consignment.id === item_consignment);
                        same_consignment.push(consignment_filter);
                    })
                    same_consignment.forEach(consignment_goods => {
                        for (let i = 0; i < consignment_goods.length; i++) {
                            customer_name = consignment_goods[i].customer.firstname;
                            customer_phone = consignment_goods[i].customer.cellphone;
                            consignmentDataRows.push(
                                {
                                    PKGS: {
                                        text: consignment_goods[i].consignment.pkgs,
                                        rowSpan: consignment_goods.length,
                                        style: 'small'
                                    },
                                    BN: {
                                        text: consignment_goods[i].consignment.label,
                                        rowSpan: consignment_goods.length,
                                        style: 'small'
                                    },
                                    QTY: {text: consignment_goods[i].quantity, style: 'small'},
                                    UNIT: {text: consignment_goods[i].unit, style: 'small'},
                                    DESCRIPTION: {text: consignment_goods[i].good.name, style: 'small'},
                                    RECEIPT: {text: consignment_goods[i].good.supplier_receipt_no, style: 'small'},
                                    CBM: {
                                        text: consignment_goods[i].consignment.cbm,
                                        rowSpan: consignment_goods.length,
                                        style: 'small'
                                    },
                                    AMOUNT: {
                                        text: consignment_goods[i].consignment.shipping_charge,
                                        rowSpan: consignment_goods.length,
                                        style: 'small'
                                    },
                                }
                            )
                        }
                    });

                    //Do not uncomment
                    // console.log(consignmentDataRows);
                    //
                    // docDefinition.content.push({
                    //     text: 'Customer Name: ' + customer_name + '  ' + ' Tel: ',
                    //     style: 'subheader'
                    // });

                    docDefinition.content.push(
                        this.table(consignmentDataRows,
                            ['PKGS',
                                'BN',
                                'QTY',
                                'UNIT',
                                'DESCRIPTION',
                                'RECEIPT',
                                'CBM',
                                'AMOUNT'
                            ],
                            customer_name,
                            customer_phone,
                            sum_pkgs,
                            sum_cbm,
                            sum_amount)
                    );
                });
                console.log(docDefinition)
                pdfMake.createPdf(docDefinition).download(`packing_list_${c_no}.pdf`);

            } else {
                alertService.warn('Sorry, We can not generate packing list because container is empty', {
                    autoClose: true,
                    keepAfterRouteChange: false
                })
            }
        });
        this.setState({openPackingList: false})
    };

    render() {
        const cs = customStyle;
        const {
            openView,
            deleteView,
            openCustomerPackingList,
            oldCustomerPackingList,
            openPackingList,
            open,
            containerDetails,
            containers,
            previous,
            next,
            loading_customers,
            loading_data,
            loading,
            searchTerm,
            startDate,
            endDate,
            containerInfo
        } = this.state;

        const handleClick = (container) => {
            this.setState({open: true, containerInfo: container});
        };

        const handleView = (container) => {
            this.setState({openView: true, containerInfo: container});
        };
        const
            columns = [
                {field: 'id', headerName: 'ID', width: 90, hide: true},
                {
                    field: 'name',
                    headerName: 'Full Name',
                    width: 450,
                },
                {
                    field: 'cellphone',
                    headerName: 'Mobile No',
                    width: 450,
                },
                {
                    field: '',
                    headerName: 'Tracking No',
                    width: 450,
                },
                {
                    field: "",
                    headerName: "Actions",
                    sortable: false,
                    width: 300,
                    type: 'string',
                    renderCell: (params) => (
                        <span>
                            <Tooltip title="Generate Packing List">
                            <IconButton aria-label="view" onClick={() => this.handleSpecial(params)}>
                              <AssignmentIndIcon fontSize="small"/>
                            </IconButton>
                            </Tooltip>
                    </span>
                    )
                },

            ];

        const handleLoaded = (container) => {
            this.setState({
                    openCustomerPackingList: true,
                    loading_data: true,
                    containerInfo: container,
                }
            );
            const container_id = container.id;
            const removeDuplicates = (duplicates) => {
                const flag = {};
                const unique = [];
                duplicates.forEach(elem => {
                    if (!flag[elem.customer.id]) {
                        flag[elem.customer.id] = true;
                        unique.push(elem);
                    }
                });
                return unique;
            }
            axios.get(`${LOCAL_URL}cargo/api/get_shipped_container/?c=` + container_id).then((response) => {
                const r = response.data;
                if (r.length > 0) {
                    const unique_customers = removeDuplicates(r);
                    const container_details = unique_customers.map(d => ({
                        "id": d.customer.id,
                        "name": d.customer.firstname,
                        "cellphone": d.customer.cellphone
                    }))
                    this.setState({
                        containerDetails: container_details,
                        loading_data: false,
                    })
                } else {
                    this.setState({
                        loading_data: false,
                    })
                }
            });
        };

        const handleClose = () => {
            this.setState({
                open: false,
                openView: false,
                deleteView: false,
                openPackingList: false,
                openCustomerPackingList: false,
                oldCustomerPackingList: false,
            })
        };

        // const handleDeleteView = (container) => {
        //     this.setState({deleteView: true, containerInfo: container})
        // }

        const handlePackingListView = (container) => {
            this.setState({openPackingList: true, containerInfo: container})
        }

        const handleCustomerPackingList = (container) => {
            this.setState({
                oldCustomerPackingList: true,
                loading_customers: true
            })
            const container_id = container.id
            const removeDuplicates = (duplicates) => {
                const flag = {};
                const unique = [];
                duplicates.forEach(elem => {
                    if (!flag[elem.customer.id]) {
                        flag[elem.customer.id] = true;
                        unique.push(elem);
                    }
                });
                return unique;
            }
            axios.get(`${LOCAL_URL}cargo/api/get_shipped_container/?c=` + container_id).then((response) => {
                const r = response.data;
                const unique_customers = removeDuplicates(r);
                const customers = unique_customers.map(d => ({
                    "value": d.customer.id,
                    "label": d.customer.firstname + " " + d.customer.lastname + " " + d.customer.cellphone
                }))
                this.setState({
                    containerInfo: container,
                    selectCustomers: customers,
                    loading_customers: false,
                })
            });
        }

        const {classes} = this.props;

        const renderDate = (container) => {
            const created = container.created_date;
            return created.substr(0, created.indexOf('T'));
        }

        const renderStatus = (container) => {
            if (container.shipping_status === 0) {
                return <span>
                     <Chip size="small" label="Booked"
                           color="primary"/>
                </span>;
            } else if (container.shipping_status === 1) {
                return <span>
                     <Chip size="small" label="Loaded"
                           color="primary" style={{backgroundColor: "teal"}}/>
                </span>;
            } else if (container.shipping_status === 2) {
                return <span>
                     <Chip size="small" label="Shipped"
                           color="primary" style={{backgroundColor: "blue"}}/>
                </span>;
            } else if (container.shipping_status === 3) {
                return <span>
                     <Chip size="small" label="Port"
                           color="primary" style={{backgroundColor: "green"}}/>
                </span>;
            } else if (container.shipping_status === 4) {
                return <span>
                     <Chip size="small" label="Arrived"
                           color="primary" style={{backgroundColor: "green"}}/>
                </span>;
            } else if (container.shipping_status === 5) {
                return <span>
                     <Chip size="small" label="Canceled"
                           color="primary" style={{backgroundColor: "red"}}/>
                </span>;
            }
        }

        const handleShippingStatus = (containerInfo) => {
            if (containerInfo.shipping_status === 0) {
                const htmlString = "<h1>Booked</h1>";
                return <div dangerouslySetInnerHTML={{__html: htmlString}}>
                </div>;
            } else if (containerInfo.shipping_status === 1) {
                const htmlString = "<h1>Loaded</h1>";
                return <div dangerouslySetInnerHTML={{__html: htmlString}}>
                </div>;
            } else if (containerInfo.shipping_status === 2) {
                return <span>
                     Port
                </span>;
            } else if (containerInfo.shipping_status === 3) {
                return <span>
                     Port
                </span>;
            } else if (containerInfo.shipping_status === 4) {
                return <span>
                     Arrived
                </span>;
            } else if (containerInfo.shipping_status === 5) {
                return <span>
                     Cancelled
                </span>;
            }
        };

        const renderLoaded = (container) => {
            if (container.shipping_status === 0) {
                return <span>

                </span>;
            } else {
                return <span>
                    <Tooltip title="Container Details">
                        <IconButton aria-label="view"
                                    onClick={() => handleLoaded(container)}>
                            <VisibilityIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                </span>;
            }
        }

        return (
            <div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 ">
                            <div className="header mt-md-5">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h1 className="header-title">
                                                <i className="fe fe-clipboard"/>&nbsp;&nbsp;&nbsp;
                                                All Containers
                                            </h1>
                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/containers/new'}>
                                                    <Button variant="contained" startIcon={<AddIcon/>}>Add
                                                        Container</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 ">
                            {loading ? (
                                <div className='text-center'>
                                    <div className='spinner-border text-primary align-content-center' role='status'>
                                        {' '}
                                        <span className='sr-only'>Loading...</span>{' '}
                                    </div>
                                </div>
                            ) : (
                                <div className="content">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <input
                                                name="searchTerm"
                                                id="searchTerm"
                                                type="text"
                                                placeholder="Container No"
                                                value={searchTerm}
                                                onChange={this.handleChange}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <button onClick={this.searchPage}>Search
                                            </button>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <input
                                                type="text"
                                                onFocus={this._onStartFocus}
                                                onBlur={this._onStartDate}
                                                name="startDate"
                                                id="startDate"
                                                placeholder="Start Date"
                                                value={startDate}
                                                onChange={this.handleChange}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <input
                                                type="text"
                                                onFocus={this._onEndFocus}
                                                onBlur={this._onEndDate}
                                                name="endDate"
                                                id="endDate"
                                                value={endDate}
                                                onChange={this.handleChange}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <button onClick={this.ExportToExcel}>Export To Excel
                                            </button>
                                        </div>
                                        <div className='col-auto'>
                                            <div className="pagination">
                                                <button disabled={previous === null} onClick={this.prevPage}>Previous
                                                </button>
                                                &nbsp;&nbsp;
                                                <button disabled={next === null} onClick={this.nextPage}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <TableContainer className={classes.container} component={Paper}>
                                        <Table stickyHeader className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>Container No</TableCell>
                                                    <TableCell>Shipping Line</TableCell>
                                                    <TableCell>Volume</TableCell>
                                                    <TableCell>Origin</TableCell>
                                                    <TableCell>Destination</TableCell>
                                                    <TableCell>Shipping Date</TableCell>
                                                    <TableCell>Arrival Date</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {containers.map((container) => {
                                                    return <>
                                                        <TableRow key={container.id}>
                                                            <TableCell>{renderDate(container)}</TableCell>
                                                            <TableCell>{container.container_no}</TableCell>
                                                            <TableCell>{container.shipping_line}</TableCell>
                                                            <TableCell>{container.volume}</TableCell>
                                                            <TableCell>{container.origin}</TableCell>
                                                            <TableCell>{container.destination}</TableCell>
                                                            <TableCell>{container.shipping_date}</TableCell>
                                                            <TableCell>{container.arrival_date}</TableCell>
                                                            <TableCell>{renderStatus(container)}</TableCell>
                                                            <TableCell>
                                                                <Tooltip title="Generate Packing List">
                                                                    <IconButton aria-label="view"
                                                                                onClick={() => handlePackingListView(container)}>
                                                                        <DescriptionIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                {renderLoaded(container)}
                                                                <Tooltip title="Customer Packing List">
                                                                    <IconButton aria-label="view"
                                                                                onClick={() => handleCustomerPackingList(container)}>
                                                                        <AssignmentIndIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="View">
                                                                    <IconButton aria-label="view"
                                                                                onClick={() => handleView(container)}>
                                                                        <InfoIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Edit">
                                                                    <IconButton aria-label="update"
                                                                                onClick={() => handleClick(container)}>
                                                                        <EditIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                {/*<Tooltip title="Delete">*/}
                                                                {/*    <IconButton aria-label="update"*/}
                                                                {/*                onClick={() => handleDeleteView(container)}>*/}
                                                                {/*        <DeleteIcon fontSize="small"/>*/}
                                                                {/*    </IconButton>*/}
                                                                {/*</Tooltip>*/}
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <form onSubmit={this.handleEdit} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Edit Container</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    To edit to this container, please edit the fields below.
                                </DialogContentText>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_container_no">Container No</label>
                                            <input
                                                type='text'
                                                name='container_no'
                                                className='form-control'
                                                required='required'
                                                value={open ? containerInfo.container_no : ""}
                                                id='id_container_no'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_shipping_line">Shipping Line</label>
                                            <select name="shipping_line" className="required form-control" required=""
                                                    id="id_shipping_line"
                                                    value={open ? containerInfo.shipping_line : ""}
                                                    onChange={this.handleChangeEdit}>
                                                <option value="APL">APL</option>
                                                <option value="PIL">PIL</option>
                                                <option value="CMA">CMA</option>
                                                <option value="MAERSK">MAERSK</option>
                                                <option value="COSCO">COSCO</option>
                                                <option value="HPL">HPL</option>
                                                <option value="ZIM">ZIM</option>
                                            </select>
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_volume">Volume</label>
                                            <select name="volume" className="required form-control" required="required"
                                                    id="id_volume" value={open ? containerInfo.volume : ""}
                                                    onChange={this.handleChangeEdit}>
                                                <option value="40HQ">40HQ</option>
                                                <option value="20HQ">20HQ</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_origin">Origin</label>
                                            <select name="origin" className="required form-control" required="required"
                                                    id="id_origin" value={open ? containerInfo.origin : ""}
                                                    onChange={this.handleChangeEdit}>
                                                <option value="Guangzhou"> Guangzhou China</option>
                                                <option value="Shanghai"> Shanghai China</option>
                                                <option value="Yiwu">Yiwu China</option>
                                            </select>
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_destination">Destination</label>
                                            <select name="destination" className="required form-control"
                                                    required="required"
                                                    id="id_destination" value={open ? containerInfo.destination : ""}
                                                    onChange={this.handleChangeEdit}>
                                                <option value="Dar es Salaam"> Dar es Salaam Tanzania</option>
                                                <option value="ZNZ"> Zanzibar Tanzania</option>
                                                <option value="Mombasa"> Mombasa Kenya</option>
                                                <option value="Kampala">Kampala Uganda</option>
                                            </select>
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_cbm">Seal Number</label>
                                            <input
                                                type='text'
                                                name='seal_no'
                                                className='form-control'
                                                required='required'
                                                value={open ? containerInfo.seal_no : ""}
                                                id='id_seal_no'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_shipping_date">Shipping Date</label>
                                            <input
                                                type='date'
                                                name='shipping_date'
                                                className='form-control'
                                                required='required'
                                                value={open ? containerInfo.shipping_date : ""}
                                                id='id_shipping_date'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_arrival_date">Arrival Date</label>
                                            <input
                                                type='date'
                                                name='arrival_date'
                                                className='form-control'
                                                required='required'
                                                value={open ? containerInfo.arrival_date : ""}
                                                id='id_arrival_date'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-12 col-xl-12'>
                                        <div className="form-group">
                                            <label htmlFor="id_status">Status</label>
                                            <select name="shipping_status" className="required form-control"
                                                    required="required"
                                                    id="id_shipping_status"
                                                    value={open ? handleShippingStatus(containerInfo) : ""}
                                                    onChange={this.handleChangeEdit}>
                                                <option value="0">Booked</option>
                                                <option value="1">Loaded</option>
                                                <option value="2">Shipped</option>
                                                <option value="3">Port</option>
                                                <option value="4">Arrived</option>
                                                <option value="5">Cancelled</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    Cancel
                                </Button>
                                <Button type='submit' color="primary">
                                    Edit
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={deleteView}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <form onSubmit={this.handleDelete} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Delete Container</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete container number {containerInfo.container_no}?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    No
                                </Button>
                                <Button type='submit' color="primary">
                                    Yes
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={openPackingList}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <form onSubmit={this.handleGeneratePdf} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Generate Container Packing List</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to generate packing list for container
                                    number {containerInfo.container_no}?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    No
                                </Button>
                                <Button type='submit' color="primary">
                                    Yes
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={oldCustomerPackingList}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        scroll='paper'
                        fullWidth={true}>

                        <form onSubmit={this.handleCustomerPackingListPdf} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Generate Customer Packing List</DialogTitle>
                            <DialogContent>
                                <div className='row'>
                                    <div className='col-12 col-md-12 col-xl-12'>
                                        {loading_customers ? (
                                            <div className='text-center'>
                                                <div className='spinner-border text-primary align-content-center'
                                                     role='status'>
                                                    {' '}
                                                    <span className='sr-only'>Loading...</span>{' '}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="form-group pd-btm">
                                                <label htmlFor="id_customer">Select Customer</label>
                                                <Select
                                                    options={this.state.selectCustomers}
                                                    onChange={this.handleSelectCustomer.bind(this)}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    No
                                </Button>
                                <Button type='submit' color="primary">
                                    Yes
                                </Button>
                            </DialogActions>
                        </form>

                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={openView}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <DialogTitle id="form-dialog-title">About Container</DialogTitle>
                        <DialogContent>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Container Number:</strong> {openView ? containerInfo.container_no : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Shipping
                                            Line:</strong> {openView ? containerInfo.shipping_line : ""} USD
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Seal Number:</strong> {openView ? containerInfo.seal_no : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Volume:</strong> {openView ? containerInfo.volume : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Origin:</strong> {openView ? containerInfo.origin : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Destination:</strong> {openView ? containerInfo.destination : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Shipping Date:</strong> {openView ? containerInfo.shipping_date : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Arrival Date:</strong> {openView ? containerInfo.arrival_date : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Created
                                            By:</strong> {openView ? containerInfo.user.first_name + " " + containerInfo.user.last_name : ""}
                                    </Typography>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        fullScreen
                        open={openCustomerPackingList}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                        aria-labelledby="form-dialog-title">
                        <AppBar className={cs.appBar}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                    <CloseIcon/>
                                </IconButton>
                                <Typography variant="h6" className={cs.title}>
                                    {openCustomerPackingList ? containerInfo.container_no + " " + containerInfo.shipping_line + " Arrival Date: " + containerInfo.arrival_date : ""}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <div className='pd-top'>
                                <div className="pb-2">
                                    <Alert severity="info">
                                        {openCustomerPackingList ? containerInfo.container_no : ""} has been loaded
                                        below are customer details!
                                    </Alert>
                                </div>
                                {loading_data ? (
                                    <div className='text-center'>
                                        <div className='spinner-border text-primary align-content-center'
                                             role='status'>
                                            {' '}
                                            <span className='sr-only'>Loading...</span>{' '}
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div style={{height: 400, width: '100%'}}>
                                            <DataGrid
                                                getRowId={(r) => r.id}
                                                rows={containerDetails}
                                                columns={columns}
                                                pageSize={5}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>

        )
    }
}

export default withStyles(useStyles)(ListContainer);
